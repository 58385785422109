import { defineStore } from 'pinia'
import { storeManagementApi, systemApi } from '@/addons/axios'
import { DrawerName, SessionKeys } from '@/addons/enums'
import i18n, { numberFormats } from '@/addons/i18n'
import {
  GetCountriesAttributes,
  GetStoreConfigDataAttributes,
  GetTranslations,
  GetVATRateDataAttributes,
} from '@/api'
import store from '@/store'
import { AuthActions } from '@/store/auth'
import { DrawerActions } from '@/store/drawer-store'
import { ConfigGetters } from '@/store/configs-store'

export interface GenericState {
  currentLanguage: string
  nations: GetCountriesAttributes[]
  nationsTranslations: GetTranslations[]
  vatCodes: GetVATRateDataAttributes
  setLanguageToDefault: boolean
}

export const useGenericStore = defineStore('GenericStore', {
  state: (): GenericState => ({
    nations: [],
    currentLanguage: i18n.global.locale,
    nationsTranslations: [],
    vatCodes: {},
    setLanguageToDefault: !sessionStorage.getItem(SessionKeys.LOCALE), // whether language should be assigned from /global_config call
  }),

  actions: {
    resetState() {
      this.$reset()
    },

    async fetchNations() {
      // console.log('useGenericStore.fetchNations')
      const countries = await systemApi.apiV1PoswebCountriesGet()
      const translations = await systemApi.apiV1PoswebTranslationsGet(
        this.currentLanguage,
        'country_code',
      )

      this.nations = countries.data.data?.map((r) => r.attributes) || []
      this.nationsTranslations = translations.data.data || []
    },
    async setCurrentLanguage(language: string) {
      this.currentLanguage = language
      await store.dispatch(AuthActions.UPDATE_AUTH_STATUS, language)
    },

    async fetchVatCodes() {
      const configs = store.getters[ConfigGetters.GET_STORE_CONFIGS] as
        | GetStoreConfigDataAttributes
        | undefined

      if (configs?.COUNTRY_CODE) {
        const response = await storeManagementApi.apiV1PoswebVatCodesGet(
          configs.COUNTRY_CODE,
        )

        this.vatCodes = response.data.data?.attributes || {}
      }
    },

    setCurrency() {
      const storeConfig = store.state.configs.currentStore
      i18n.global.setNumberFormat('en', {
        currency: numberFormats.currency(
          storeConfig?.CURRENCY_CODE,
          storeConfig?.CURRENCY_DECIMAL,
        ),
      })
    },

    openPayments() {
      store.dispatch(DrawerActions.OPEN, {
        component: DrawerName.DRAWER_PAYMENTS,
        title: i18n.global.t('pos_sale.payments'),
      })
    },
  },

  // TODO: getter probabilmente inutili perché passano i dati che si possono leggere direttamente dallo `state`,
  // a meno di non necessitare della reattività dei dati che vengono modificati di continuo
  // verificare e nel caso sostituire ed eliminare
  getters: {
    getCurrentLanguage(): string {
      return this.currentLanguage
    },
    getNations(): GetCountriesAttributes[] {
      return this.nations
    },
    getNationsTranslations(): GetTranslations[] {
      return this.nationsTranslations
    },
    getVatCodes(): GetVATRateDataAttributes {
      return this.vatCodes
    },
  },
})
