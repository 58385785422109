import store from '@/store'
import { ConfigGetters } from '@/store/configs-store'
import { FeLogger } from '@/addons/monitoring'
import { GetStoreConfigDataAttributes } from '@/api'
import { isConfigTruthy } from '@/addons/functions/configs'

type PluginInitFunction = () => Promise<void>

type PluginSetup = {
  pluginName: string
  initFn: PluginInitFunction
  setupComponentsFn?: (registerComponent: any) => void
}

const handlePluginInit = (registerComponent: any, setup: PluginSetup) => {
  const { pluginName, initFn, setupComponentsFn } = setup
  setupComponentsFn?.(registerComponent)
  FeLogger.info(`[${pluginName}]: initializing plugin`)
  initFn()
}

/**
 * Initializes a plugin with the provided configuration.
 *
 * @param pluginName - The name of the plugin to initialize.
 * @param initFn - The function to initialize the plugin.
 * @param setupComponentsFn - An optional function to set up the plugin's components.
 * @returns An object with a `definePluginComponents` method that handles the plugin initialization.
 */
export const initializePlugin = (
  pluginName: string,
  initFn: PluginInitFunction,
  setupComponentsFn?: (registerComponent: any) => void,
) => ({
  definePluginComponents: (registerComponent: any) => {
    handlePluginInit(registerComponent, {
      pluginName,
      initFn,
      setupComponentsFn,
    })
  },
})

/**
 * Initializes a plugin with a configuration key. The plugin will only be initialized if the configuration for the provided key is truthy.
 *
 * @param pluginName - The name of the plugin.
 * @param configKey - The key of the store configuration to check.
 * @param initFn - The function to initialize the plugin.
 * @param setupComponentsFn - An optional function to set up the plugin's components.
 * @returns An object with a `definePluginComponents` method that handles the plugin initialization.
 */
export const initializePluginWithConfig = (
  pluginName: string,
  configKey: keyof GetStoreConfigDataAttributes,
  initFn: PluginInitFunction,
  setupComponentsFn?: (registerComponent: any) => void,
) => ({
  definePluginComponents: (registerComponent: any) => {
    FeLogger.info(`[${pluginName}]: waiting for store configs`)

    const initializeIfEnabled = () => {
      if (isConfigTruthy(configKey)) {
        handlePluginInit(registerComponent, {
          pluginName,
          initFn,
          setupComponentsFn,
        })
      }
    }

    if (store.getters[ConfigGetters.GET_STORE_CONFIGS]) {
      initializeIfEnabled()
    }

    document.addEventListener('storeConfigsLoaded', initializeIfEnabled)
  },
})

const logConfigStatus = (
  param: keyof GetStoreConfigDataAttributes,
  source: string,
) => {
  const isEnabled = isConfigTruthy(param)
  FeLogger.info(
    `[LegacyModules] Configs loaded ${source}, param ${param} is ${isEnabled}`,
  )
  return isEnabled
}

/**
 * Checks the legacy module configuration based on the provided parameter.
 *
 * If no parameter is provided, it returns `true`.
 * If the store configs are already loaded, it checks if the configuration is truthy and returns the result.
 * If the store configs are not yet loaded, it sets up a listener for the `storeConfigsLoaded` event and returns `false` until the configs are loaded.
 *
 * @param param - The key of the store configuration to check.
 * @returns `true` if the configuration is truthy or no parameter is provided, `false` otherwise.
 */
export const checkLegacyModuleConfig = (
  param: keyof GetStoreConfigDataAttributes,
): boolean => {
  // Debug purpose only
  // FeLogger.info(`[LegacyModules] param: ${param}`)

  if (!param) {
    FeLogger.info('[LegacyModules] No param provided, returning true')
    return true
  }

  if (store.getters[ConfigGetters.GET_STORE_CONFIGS]) {
    return logConfigStatus(param, 'from store')
  }

  // Debug purpose only
  // FeLogger.info(
  //   '[LegacyModules] Store configs not loaded, setting up listener',
  // )
  document.addEventListener('storeConfigsLoaded', () => {
    return logConfigStatus(param, 'via event')
  })

  return false
}

/**
 * Usage examples:
 *
 * 1. Plugin without config check:
 * const init = async () => {
 *   loadRegistry()
 *   setupAnalytics()
 *   registerEvents()
 * }
 *
 * export default initializePlugin(
 *   'Analytics Plugin',
 *   init,
 *   (register) => register('analytics-dashboard', () => import('./components/Dashboard.vue'))
 * )
 *
 * 2. Plugin with config check:
 * const init = async () => {
 *   loadRegistry()
 *   await getVertexAuth()
 *   setupListeners()
 * }
 *
 * export default initializePluginWithConfig(
 *   'Vertex AI Plugin',
 *   'IS_REC_AI_ENABLED',
 *   init,
 *   setupComponents.definePluginComponents
 * )
 */
