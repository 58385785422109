import { FeLogger } from '@/addons/monitoring'
import store from '@/store'
import { ConsumersGetters } from '@/store/consumer/index'
import { SalesGetters } from '@/store/sales/sales-store'
import { ConfigGetters } from '@/store/configs-store'

export const NAVIGATE_TO = 'navigate-to'
export const CHANGE_CUSTOMER = 'change-customer'
export const CHANGE_SALES_MANAGER = 'change-sales-manager'
export const EA_HOME_PAGE_VIEW = 'ea-home-page-view'
export const EA_CATEGORY_PAGE_VIEW = 'ea-category-page-view'
export const EA_ADD_TO_FITTING_ROOM = 'ea-add-to-fitting-room'
export const EA_PRODUCT_SEARCH = 'ea-product-search'
export const EA_PRODUCT_DETAILS_VIEW = 'ea-product-details-view'
export const COMPLETE_PURCHASE = 'complete-purchase'
export const SUSPEND_PURCHASE = 'suspend-purchase'
export const ADD_TO_CART = 'add-to-cart'

export interface Store {
  code: string
  brand: string
}

export interface SalesManager {
  name: string
  surname: string
  code: string
}

export interface Customer {
  name: string
  surname: string
  code: string
}

export interface Product {
  sku: string
  model: string
  color?: string
  size?: string
  model_code?: string
  price: {
    currentPrice: string
    fullPrice?: string
    currency: string
    discount?: number
  }
}

export interface Page {
  name: string
  referrer: string
}

export interface Cart {
  id: number
  purchaseType: string
  products: {
    product: Product
    quantity: number
  }[]
  purchaseTransaction: PurchaseTransaction
}

export interface BaseData {
  user: SalesManager
  customer: Customer
  store: Store
}

export interface PurchaseTransaction {
  revenue: number
  currencyCode: string
}

const getBaseData = (): BaseData => {
  const user: any = () => store.getters[SalesGetters.GET_CASHIER]
  const customer: any = () =>
    store.getters[ConsumersGetters.GET_CURRENT_CONSUMER]
  const anonymous: any = () => store.getters[SalesGetters.GET_ANONYMOUS_ID]
  return {
    user: {
      code: user()?.venditrice,
      name: user()?.descrizione,
      surname: user()?.descrizione,
    },
    customer: {
      name: customer()?.name || customer()?.nome || '',
      surname: customer()?.surname || customer()?.cognome || '',
      code: anonymous() || customer()?.pk_consumer || 1,
    },
    store: {
      code: store.getters[ConfigGetters.STORE_CODE],
      brand: store.getters[ConfigGetters.GET_STORE_CONFIGS]?.STORE_SIGN,
    },
  }
}

/**
 * Navigate to a page event
 * @param page
 */
export const navigateToEvent = (page: Page) => {
  const data = {
    ...getBaseData(),
    page,
  }

  const event = new CustomEvent(NAVIGATE_TO, {
    detail: data,
  })
  document.dispatchEvent(event)
  FeLogger.info(`${NAVIGATE_TO.toUpperCase()} custom event`, data)
}

/**
 * Change customer event
 * @param customer
 */
export const changeCustomerEvent = (customer: Customer) => {
  const data = {
    ...getBaseData(),
    customer,
  }

  const event = new CustomEvent(CHANGE_CUSTOMER, {
    detail: data,
  })
  document.dispatchEvent(event)
  FeLogger.info(`${CHANGE_CUSTOMER.toUpperCase()} custom event`, data)
}

/**
 * Change sales manager event
 * @param salesManager
 */
export const changeSalesManagerEvent = (salesManager: SalesManager) => {
  const data = {
    ...getBaseData(),
    salesManager,
  }

  const event = new CustomEvent(CHANGE_SALES_MANAGER, {
    detail: data,
  })
  document.dispatchEvent(event)
  FeLogger.info(`${CHANGE_SALES_MANAGER.toUpperCase()} custom event`, data)
}

/**
 * Home page view event
 */
export const homePageViewEvent = () => {
  const event = new CustomEvent(EA_HOME_PAGE_VIEW, {
    detail: { ...getBaseData() },
  })
  document.dispatchEvent(event)
  FeLogger.info(
    `${EA_HOME_PAGE_VIEW.toUpperCase()} custom event`,
    getBaseData(),
  )
}

/**
 * Category page view event
 * @param category
 */
export const categoryPageViewEvent = (category: string) => {
  const data = {
    ...getBaseData(),
    category,
  }

  const event = new CustomEvent(EA_CATEGORY_PAGE_VIEW, {
    detail: data,
  })

  document.dispatchEvent(event)
  FeLogger.info(`${EA_CATEGORY_PAGE_VIEW.toUpperCase()} custom event`, data)
}

/**
 * Add to fitting room event
 * @param product
 */
export const addToFittingRoomEvent = (product: Product) => {
  const data = {
    ...getBaseData(),
    product,
  }

  const event = new CustomEvent(EA_ADD_TO_FITTING_ROOM, {
    detail: data,
  })
  document.dispatchEvent(event)
  FeLogger.info(`${EA_ADD_TO_FITTING_ROOM.toUpperCase()} custom event `, data)
}

/**
 * Product search event
 * @param query
 */
export const productSearchEvent = (query: { string: string }) => {
  const data = {
    ...getBaseData(),
    query,
  }

  const event = new CustomEvent(EA_PRODUCT_SEARCH, {
    detail: data,
  })
  document.dispatchEvent(event)
  FeLogger.info(`${EA_PRODUCT_SEARCH.toUpperCase()} custom event`, data)
}

/**
 * Product details view event
 * @param product
 */
export const productDetailsViewEvent = (product: Product) => {
  const data = {
    ...getBaseData(),
    product,
  }

  const event = new CustomEvent(EA_PRODUCT_DETAILS_VIEW, {
    detail: data,
  })
  document.dispatchEvent(event)
  FeLogger.info(`${EA_PRODUCT_DETAILS_VIEW.toUpperCase()} custom event`, data)
}

/**
 * Complete purchase event
 * @param purchase
 */
export const completePurchaseEvent = (purchase: Cart) => {
  const data = {
    ...getBaseData(),
    purchase,
  }

  const event = new CustomEvent(COMPLETE_PURCHASE, {
    detail: data,
  })
  document.dispatchEvent(event)
  FeLogger.info(`${COMPLETE_PURCHASE.toUpperCase()} custom event`, data)
}

/**
 * Suspend purchase event
 * @param purchase
 */
export const suspendPurchaseEvent = (purchase: { id: string; cart: Cart }) => {
  const data = {
    ...getBaseData(),
    purchase,
  }

  const event = new CustomEvent(SUSPEND_PURCHASE, {
    detail: data,
  })
  document.dispatchEvent(event)
  FeLogger.info(`${SUSPEND_PURCHASE.toUpperCase()} custom event`, data)
}

/**
 * Add to cart event
 * @param product
 */
export const addToCartEvent = (products: Product[]) => {
  const data = {
    ...getBaseData(),
    products,
  }

  const event = new CustomEvent(ADD_TO_CART, {
    detail: data,
  })
  document.dispatchEvent(event)
  FeLogger.info(`${ADD_TO_CART.toUpperCase()} custom event`, data)
}
