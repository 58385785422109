<script lang="ts">
import { PageContexts } from '@/addons/enums'
import i18n from '@/addons/i18n'
import feHeader from '@/components/layout/page-header.vue'
import modalController, { ModalTypes } from '@/components/modal/ModalController'
import {
  BarcodeScanningMode,
  BarcodeValidatorFunction,
} from '@/abs-core/features/barcode-scanner/barcode-interfaces'
import {
  BarcodeScanner,
  ScanOptions,
  SupportedFormat,
} from '@capacitor-community/barcode-scanner'
import { ScreenOrientation } from '@capacitor/screen-orientation'

import { IonPage } from '@ionic/vue'
import { PropType, defineComponent } from 'vue'

const BARCODE_SCANNER_CLASS = 'has-barcode-scanner'

export default defineComponent({
  name: 'FeBarcodeScanner',
  components: {
    IonPage,
    feHeader,
  },

  props: {
    context: {
      type: String as PropType<PageContexts>,
      default: PageContexts.FRONTOFFICE,
      validator(value: PageContexts) {
        return Object.values(PageContexts).includes(value)
      },
    },

    container: {
      type: Object as PropType<HTMLElement>,
      required: true,
    },
    hideExtraContainers: {
      type: Array as PropType<Array<HTMLElement>>,
      default: () => [],
      required: false,
    },

    validator: {
      type: Function as PropType<BarcodeValidatorFunction>,
      default: () => Promise.resolve(true),
    },

    mode: {
      type: String as PropType<BarcodeScanningMode>,
      default: 'single',
    },
  },

  emits: ['results', 'barcode'],

  data() {
    return {
      barcodes: {} as Record<string, string>,
    }
  },

  mounted() {
    // Start scanning for barcodes immediately.
    this.startScan()
  },

  unmounted() {
    // If we were requested to read multiple barcodes in a single shot, make sure to emit those we have read so far.
    // This ensures that the component that has requested to read multiple barcodes gets them. We do not need to do
    // it also when scanning mode has been set to `single` since read barcode would be emitted somewhere else.
    if (this.mode === 'multiple') {
      this.$emit('results', Object.keys(this.barcodes))
    }

    // Since we got unmounted, we can stop the barcode scanning process.
    this.showBackground()
  },

  methods: {
    /**
     * Starts the barcode scanning process. This method first checks if the user has granted the necessary permissions,
     * and if not, it requests the permissions. It then sets up the scanning options, including the targeted barcode
     * formats, the scan area dimensions, and the viewport size. The method then starts the scanning process and
     * handles the results, validating the barcodes and emitting the appropriate events. If the scanning mode is set
     * to 'multiple', the method will emit the results of all valid barcodes read during the scan. If the mode is set
     * to 'single', the method will emit the first valid barcode and stop the scan.
     */
    async startScan() {
      const grantedPermissions = await this.didUserGrantPermission()

      if (!grantedPermissions) {
        await BarcodeScanner.checkPermission({ force: false })
      }

      try {
        // make background of WebView transparent
        this.hideBackground()

        const width = 400
        const height = 100

        const viewport = this.$refs.viewport as HTMLDivElement | undefined
        const scanOption: ScanOptions = {
          coords: [
            Math.round((window.innerWidth - width) / 2),
            Math.round((window.innerHeight - height) / 2),
          ],
          width,
          height,
          targetedFormats: [
            SupportedFormat.UPC_A,
            SupportedFormat.UPC_E,
            SupportedFormat.UPC_EAN_EXTENSION,
            SupportedFormat.EAN_8,
            SupportedFormat.EAN_13,
            SupportedFormat.CODE_39,
            SupportedFormat.CODE_39_MOD_43,
            SupportedFormat.CODE_93,
            SupportedFormat.CODE_128,
            SupportedFormat.CODABAR,
            SupportedFormat.ITF,
            SupportedFormat.ITF_14,
          ],
        }

        if (typeof viewport !== 'undefined') {
          viewport.style.width = `${width / 10}rem`
          viewport.style.height = `${height / 10}rem`
        }

        const barcodes = await new Promise<string[]>((resolve) => {
          BarcodeScanner.startScanning(scanOption, async (result) => {
            const barcode = result.hasContent ? result.content : undefined

            // Either user requested to stop current scan or we have just seen an unreadable barcode.
            // Either way, we can return whatever we read up until this point.
            if (!barcode) {
              return resolve(Object.keys(this.barcodes))
            }

            // Check whether barcode we just read can be considered valid
            if (await this.validator(barcode)) {
              this.barcodes[barcode] = barcode

              // Since we got a valid barcode, emit the appropriate event.
              this.$emit('barcode', barcode)

              // If we need a single barcode, we can short circuit out.
              if (this.mode === 'single') {
                return resolve(Object.keys(this.barcodes))
              }
            }
          })
        })

        // Emit an event and return barcodes we just read.
        this.$emit('results', barcodes)
      } finally {
        this.showBackground()
      }
    },

    /**
     * Hides the background, adds the barcode scanner class to the container
     * Find any extra containers,
     * and locks the screen orientation to portrait mode.
     */
    async hideBackground() {
      await BarcodeScanner.hideBackground()
      this.container?.classList.add(BARCODE_SCANNER_CLASS)
      for (let element of this.hideExtraContainers) {
        element.classList.add(BARCODE_SCANNER_CLASS)
      }
      await ScreenOrientation.lock({ orientation: 'portrait' })
    },

    /**
     * Shows the background, stops the barcode scanner,
     * removes the barcode scanner class from the container and any extra containers,
     * and unlocks the screen orientation.
     */
    async showBackground() {
      await BarcodeScanner.showBackground()
      await BarcodeScanner.stopScan({ resolveScan: true })

      this.container?.classList.remove(BARCODE_SCANNER_CLASS)
      for (let element of this.hideExtraContainers) {
        element.classList.remove(BARCODE_SCANNER_CLASS)
      }
      await ScreenOrientation.unlock()
    },

    /**
     * Checks if the user has granted permission to access the camera for the barcode scanner.
     * If permission is granted, it returns `true`. If permission is denied, it displays an error modal
     * and opens the app settings. If permission is restricted or unknown, it returns `false`.
     * If permission is not granted, it requests permission and returns `true` if the request is successful.
     *
     * @returns {Promise<boolean>} `true` if the user has granted permission, `false` otherwise.
     */
    async didUserGrantPermission() {
      const status = await BarcodeScanner.checkPermission({ force: false })
      if (status.granted) {
        return true
      }

      if (status.denied) {
        modalController.open({
          type: ModalTypes.ERROR,
          title: i18n.global.t('pos_common.error'),
          component: '',
          message: i18n.global.t('pos_sale.camera_permission_denied'),
          confirmLabel: i18n.global.t('pos_common.continue'),
          confirmActionButton: true,
          confirmAction: () => {
            BarcodeScanner.openAppSettings()
          },
          closedAction: () => {
            this.$router.back()
          },
        })
      }

      if (status.restricted || status.unknown) {
        return false
      }

      const statusRequest = await BarcodeScanner.checkPermission({
        force: true,
      })

      if (statusRequest.granted) {
        return true
      }

      return false
    },
  },
})
</script>

<template>
  <ion-page id="feBarcodeScanner">
    <fe-header
      :context="context"
      :title="$t('pos_sale.barcode_title')"
    ></fe-header>
    <div id="container">
      <div id="viewport" ref="viewport"></div>
    </div>
  </ion-page>
</template>

<style lang="scss">
@import '@/styles/_constants.scss';

$fallbackWidth: 40rem;
$fallbackHeight: 10rem;

#feBarcodeScanner {
  & #container {
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;

    & #viewport {
      width: $fallbackWidth;
      height: $fallbackHeight;
      border: 0.2rem solid #{$color-selected-light};
    }
  }
}
</style>
