/**
 * This code is the main entry point of the application. It performs the following tasks:
 * - Initializes the main application using `initializeApp()`
 * - Sets up the router using `setupRouter()`
 * - Configures platform-specific settings using `setupPlatformSpecific()`
 * - Parses SCSS variables from the `_mixins.scss` file and exports them as `SCSS_MIXINS`
 * - Imports global CSS styles for the application
 * - Mounts the application using the `mount()` function
 * - Exports the `app` instance for use in other parts of the application
 */

import { initializeApp } from './main-app-init'
import { setupRouter } from './router/main-router-guards'
import { setupPlatformSpecific } from './main-platform-specific'

// Style imports
import { parseScssVariabiles } from './addons/functions'

/**
 * Import and parse SCSS media queries from _mixins.scss using Vite's ?inline query.
 * The ?inline suffix tells Vite to import the processed SCSS output rather than the raw source,
 * giving us access to the computed values of variables and expressions.
 * @see https://vitejs.dev/guide/assets.html#importing-asset-as-string
 */
import mixinsScss from '@/styles/_mixins.scss?inline'
export const SCSS_MIXINS = parseScssVariabiles(mixinsScss)
// console.log('Parsed SCSS_MIXINS:', SCSS_MIXINS)

import '@/theme/theme.scss'
import '@ionic/vue/css/core.css'
import 'vue-toastification/dist/index.css'

const { app, mount } = initializeApp()
setupRouter()

const { setStatusBarStyle } = setupPlatformSpecific()
setStatusBarStyle()

mount()

export { app }
