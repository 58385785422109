import { FeLogger } from '@/addons/monitoring'
import { registerComponent } from '../hook-manager'

const pluginsMap = import.meta.glob('/src/abs-plugins/*/index.ts')

/**
 * Asynchronously loads and registers the specified plugins.
 *
 * This function iterates over the `activePlugins` array, attempting to load each plugin
 * from the `/src/abs-plugins/*\/index.ts` path. If the plugin is found, its default export
 * is called to register the plugin's components using the `registerComponent` function.
 * If a plugin is not found, a warning is logged.
 *
 * @param activePlugins - An array of plugin names to load and register.
 * @returns A Promise that resolves when all plugins have been loaded and registered.
 */
export async function loadPlugins(activePlugins: string[]) {
  FeLogger.info('[AbsPluginLoader] Available plugins:', Object.keys(pluginsMap))

  const pluginPromises = activePlugins.map((pluginName) => {
    const pluginKey = `/src/abs-plugins/${pluginName}/index.ts`
    FeLogger.info('[AbsPluginLoader] Attempting to load:', pluginKey)

    if (pluginsMap[pluginKey]) {
      return pluginsMap[pluginKey]()
        .then((moduleImport: any) => {
          // Debug purpose only
          // FeLogger.info('[AbsPluginLoader] Module imported:', moduleImport)
          // FeLogger.info('[AbsPluginLoader] Default export:', moduleImport.default)
          moduleImport.default.definePluginComponents(registerComponent)
        })
        .catch((_err) => {
          FeLogger.error(
            `[AbsPluginLoader] Error importing plugin ${pluginName}:`,
            _err,
          )
        })
    }
    FeLogger.warn(`[AbsPluginLoader] Plugin not found: ${pluginName}`)
    return Promise.resolve()
  })

  await Promise.all(pluginPromises)
}
