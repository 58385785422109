import { LaybyCartTypeID } from '@/addons/enums'
import { GetConsumerExtraInfoDataAttributes } from '@/api'

import { PaymentMethodForm } from '@/interfaces/payment-method-form'

import {
  Actions,
  datiStornoD,
  Getters,
  Mutations,
  SalesState,
} from './sales-types'
import {
  Actions as LaybyActions,
  Getters as LaybyGetters,
} from './layby/sales-lb-types'
import { salesGetters } from './sales-getters'
import { salesMutations } from './sales-mutations'
import { salesActions } from './sales-actions'
import { initLaybyState } from './layby/sales-lb-state'
import { laybyMutations } from './layby/sales-lb-mutations'
import { laybyGetters } from './layby/sales-lb-getters'
import { laybyActions } from './layby/sales-lb-actions'

export const initDatiStorno = (): datiStornoD => ({
  num_bollettina: '',
  data_bollettina: '',
  possiede_scontrino: 0,
  original_sale_type: 'SALE',
})

export const initState = (): SalesState => ({
  ...initLaybyState(),
  currentSale: {},
  product_detail: {},
  progressivo_capi: 0,
  progressivo_pagamenti: 0,
  consumer: undefined,
  isAnonymous: false,
  isGroupConsumer: false,
  selected_rdv: undefined,
  selected_cashier: undefined,
  skuAlertOpen: false,
  cartView: 'carousel',
  consumerStatistics: undefined,
  modalCreditCardInstance: undefined,
  suspendedCredit: {
    pagamentoRemotoPreferito: '',
    tipoProdotto: '',
    importo: '',
  },
  headerIcons: {} as GetConsumerExtraInfoDataAttributes,
  paymentData: { chequeData: {}, cashData: {} } as PaymentMethodForm,
  cartType: [
    {
      id: LaybyCartTypeID.STANDARD,
      value: 'standard',
      label: 'pos_common.standard',
    },
    {
      id: LaybyCartTypeID.ACCONTO,
      value: 'acconto',
      label: 'pos_sale.layby',
    },
    // hidden until complete feature
    // {
    //   id: LaybyCartTypeID.REMOTE_SALE,
    //   value: 'remote-sale',
    //   label: 'pos_cash_register.cash_remote_sales'
    // }
  ],
  selectedCartType: {},
  totalPriceDeposit: 0,
  totalPriceDepositPaymentMethod: 0,
  maxImportCheque: '',
  saveGiftCardInProcess: false,
  usedCash: {},
  dati_storno: initDatiStorno(),
  disabledConfirm: false,
  canChangeCartType: {
    value: true,
    message: '',
  },
  checkAntireciclaggioResult: false,
  infoGiacenzaCapi: {},
  pk_consumer: '',
})

const salesStore = {
  namespaced: true,
  state: initState,
  mutations: { ...salesMutations, ...laybyMutations },
  actions: {
    ...salesActions,
    ...laybyActions,
  },
  getters: {
    ...salesGetters,
    ...laybyGetters,
  },
}

export default salesStore

export const SalesActions = {
  OPEN_CREDIT_CARD_MODAL: `sales/${Actions.OPEN_CREDIT_CARD_MODAL}`,
  CLOSE_CREDIT_CARD_MODAL: `sales/${Actions.CLOSE_CREDIT_CARD_MODAL}`,
  RESET_STATE: `sales/${Actions.RESET_STATE}`,
  INIT_SALE: `sales/${Actions.INIT_SALE}`,
  RESET_CONSUMER: `sales/${Actions.RESET_CONSUMER}`,
  CREATE_SALE: `sales/${Actions.CREATE_SALE}`,
  SAVE_SALE: `sales/${Actions.SAVE_SALE}`,
  UPDATE_SALE: `sales/${Actions.UPDATE_SALE}`,
  SUSPEND_SALE: `sales/${Actions.SUSPEND_SALE}`,
  TRY_PAYMENT_CREDIT_CARD: `sales/${Actions.TRY_PAYMENT_CREDIT_CARD}`,
  HYDRATE_SALES: `sales/${Actions.HYDRATE_SALES}`,
  HYDRATE_SALES_SKUS: `sales/${Actions.HYDRATE_SALES_SKUS}`,
  ADD_SKU: `sales/${Actions.ADD_SKU}`,
  ADD_PAYMENT: `sales/${Actions.ADD_PAYMENT}`,
  ADD_PAYMENT_DATA: `sales/${Actions.ADD_PAYMENT_DATA}`,
  EDIT_PAYMENT: `sales/${Actions.EDIT_PAYMENT}`,
  REMOVE_PAYMENT: `sales/${Actions.REMOVE_PAYMENT}`,
  ADD_CAPO: `sales/${Actions.ADD_CAPO}`,
  UPDATE_CAPO: `sales/${Actions.UPDATE_CAPO}`,
  REMOVE_CAPO: `sales/${Actions.REMOVE_CAPO}`,
  SET_PAYMENTS: `sales/${Actions.SET_PAYMENTS}`,
  RESET_PAYMENTS: `sales/${Actions.RESET_PAYMENTS}`,
  RESET_ONLY_PAYMENTS_TYPE: `sales/${Actions.RESET_ONLY_PAYMENTS_TYPE}`,
  RESET_REBATES: `sales/${Actions.RESET_REBATES}`,
  REMOVE_REBATES_VALIDATION: `sales/${Actions.REMOVE_REBATES_VALIDATION}`,
  CONFIRM_REBATES: `sales/${Actions.CONFIRM_REBATES}`,
  CONFIRM_DISCOUNTS: `sales/${Actions.CONFIRM_DISCOUNTS}`,
  CONFIRM_TAILORING_ALTERATIONS: `sales/${Actions.CONFIRM_TAILORING_ALTERATIONS}`,
  DELETE_UNCONFIRMED_EXTRA_SERVICES: `sales/${Actions.DELETE_UNCONFIRMED_EXTRA_SERVICES}`,
  RESET_DISCOUNTS: `sales/${Actions.RESET_DISCOUNTS}`,
  REMOVE_DISCOUNTS_VALIDATION: `sales/${Actions.REMOVE_DISCOUNTS_VALIDATION}`,
  SET_DOCUMENTO_DATI: `sales/${Actions.SET_DOCUMENTO_DATI}`,
  UPDATE_DATI_DOCUMENTI: `sales/${Actions.UPDATE_DATI_DOCUMENTI}`,
  REMOVE_DATI_DOCUMENTI_KEYS: `sales/${Actions.REMOVE_DATI_DOCUMENTI_KEYS}`,
  RESET_DOCUMENTO_DATI: `sales/${Actions.RESET_DOCUMENTO_DATI}`,
  SET_DOCUMENTO_CODICE: `sales/${Actions.SET_DOCUMENTO_CODICE}`,
  RESET_DOCUMENTO_CODICE: `sales/${Actions.RESET_DOCUMENTO_CODICE}`,
  SET_NOTA: `sales/${Actions.SET_NOTA}`,
  RESET_NOTA: `sales/${Actions.RESET_NOTA}`,
  SET_CONSUMER: `sales/${Actions.SET_CONSUMER}`,
  SET_CASHIER: `sales/${Actions.SET_CASHIER}`,
  SET_MONEY_CHANGE: `sales/${Actions.SET_MONEY_CHANGE}`,
  SET_ANONYMOUS: `sales/${Actions.SET_ANONYMOUS}`,
  SET_ANONYMOUS_CONSUMER: `sales/${Actions.SET_ANONYMOUS_CONSUMER}`,
  SET_RDV: `sales/${Actions.SET_RDV}`,
  SET_DATA_VISIBILITY: `sales/${Actions.SET_DATA_VISIBILITY}`,
  ACTIVATE_GIFT_CARDS: `sales/${Actions.ACTIVATE_GIFT_CARDS}`,
  PAY_GIFT_CARD: `sales/${Actions.PAY_GIFT_CARD}`,
  UPDATE_PAYMENT_DATA: `sales/${Actions.UPDATE_PAYMENT_DATA}`,
  UPDATE_FULL_PAYMENT_DATA: `sales/${Actions.UPDATE_FULL_PAYMENT_DATA}`,
  CLOSE_SALE: `sales/${Actions.CLOSE_SALE}`,
  SET_CART_TYPE: `sales/${Actions.SET_CART_TYPE}`,
  SET_SELECTED_CART_TYPE: `sales/${Actions.SET_SELECTED_CART_TYPE}`,
  SET_TOTAL_PRICE_DEPOSIT: `sales/${Actions.SET_TOTAL_PRICE_DEPOSIT}`,
  SET_TOTAL_PRICE_DEPOSIT_PAYMENT_METHOD: `sales/${Actions.SET_TOTAL_PRICE_DEPOSIT_PAYMENT_METHOD}`,
  RESET_CART_TYPE: `sales/${Actions.RESET_CART_TYPE}`,
  CLEAR_SALE_CAPI: `sales/${Actions.CLEAR_SALE_CAPI}`,
  RESET_ANONYMOUS_CONSUMER: `sales/${Actions.RESET_ANONYMOUS_CONSUMER}`,
  SET_GIFT_RECEIPT: `sales/${Actions.SET_GIFT_RECEIPT}`,
  SET_CODICE_MOVIMENTO: `sales/${Actions.SET_CODICE_MOVIMENTO}`,
  SET_DATI_STORNO: `sales/${Actions.SET_DATI_STORNO}`,
  SET_DATI_STORNO_CURRENTSALE: `sales/${Actions.SET_DATI_STORNO_CURRENTSALE}`,
  SET_DISABLED_CONFIRM: `sales/${Actions.SET_DISABLED_CONFIRM}`,
  CHECK_PRODUCTS_STOCK: `sales/${Actions.CHECK_PRODUCTS_STOCK}`,
  SET_CAN_CHANGE_CART_TYPE: `sales/${Actions.SET_CAN_CHANGE_CART_TYPE}`,
  SET_INFO_GIACENZA_CAPI: `sales/${Actions.SET_INFO_GIACENZA_CAPI}`,
  REMOVE_INFO_GIACENZA_CAPI: `sales/${Actions.REMOVE_INFO_GIACENZA_CAPI}`,
  RESET_INFO_GIACENZA_CAPI: `sales/${Actions.RESET_INFO_GIACENZA_CAPI}`,
  STORE_ID_TRANSAZIONE: `sales/${Actions.STORE_ID_TRANSAZIONE}`,
  STORE_CREATION_DATE: `sales/${Actions.STORE_CREATION_DATE}`,
  ADD_COD_COMMESSA: `sales/${Actions.ADD_COD_COMMESSA}`,
  REMOVE_SHOPPING_BAGS: `sales/${Actions.REMOVE_SHOPPING_BAGS}`,
  CONFIRM_SHOPPING_BAGS: `sales/${Actions.CONFIRM_SHOPPING_BAGS}`,
  RESET_SHOPPING_BAGS: `sales/${Actions.RESET_SHOPPING_BAGS}`,
  RESET_SHIPPING_COST: `sales/${Actions.RESET_SHIPPING_COST}`,
  CONFIRM_SHIPPING_COST: `sales/${Actions.CONFIRM_SHIPPING_COST}`,
  // old action
  UPDATE_TAILORING: `sales/${Actions.UPDATE_TAILORING}`,
  SET_INITIAL_PRICE: `sales/${Actions.SET_INITIAL_PRICE}`,
  SET_FINAL_PRICE: `sales/${Actions.SET_FINAL_PRICE}`,
  SET_TOTAL_PAYMENTS: `sales/${Actions.SET_TOTAL_PAYMENTS}`,
  UPDATE_AVAILABLE_PAYMENTS_PROGRESSIVE: `sales/${Actions.UPDATE_AVAILABLE_PAYMENTS_PROGRESSIVE}`,
  UPDATE_CURRENT_CAPO_ALTERATIONS: `sales/${Actions.UPDATE_CURRENT_CAPO_ALTERATIONS}`,
  CHECK_SALES_TRACE: `sales/${Actions.CHECK_SALES_TRACE}`,
  COMPUTE_TOTALS: `sales/${Actions.COMPUTE_TOTALS}`,
  UPDATE_PRODUCT_DETAIL: `sales/${Actions.UPDATE_PRODUCT_DETAIL}`,
  UPDATE_PAYMENT_VALIDATION: `sales/${Actions.UPDATE_PAYMENT_VALIDATION}`,
  CHECK_COUPON: `sales/${Actions.CHECK_COUPON}`,
  CONFIRM_COUPON: `sales/${Actions.CONFIRM_COUPON}`,
  DELETE_COUPON: `sales/${Actions.DELETE_COUPON}`,
  VALIDATE_COUPON: `sales/${Actions.VALIDATE_COUPON}`,
  STORE_PAYMENT_AMOUNT: `sales/${Actions.STORE_PAYMENT_AMOUNT}`,
  UPDATE_SUSPENDED_CREDIT: `sales/${Actions.UPDATE_SUSPENDED_CREDIT}`,
  FETCH_SALES_HEADER_ICONS: `sales/${Actions.FETCH_SALES_HEADER_ICONS}`,
  CHECK_DATI_ANTIRICICLAGGIO: `sales/${Actions.CHECK_DATI_ANTIRICICLAGGIO}`,
  STAMPA_RIEPILOGO_ANTIRICICLAGGIO: `sales/${Actions.STAMPA_RIEPILOGO_ANTIRICICLAGGIO}`,
  SET_TOTAL_CAPI: `sales/${Actions.SET_TOTAL_CAPI}`,
  CHECK_TRANSACTION: `sales/${Actions.CHECK_TRANSACTION}`,
  RESET_DATI_STORNO: `sales/${Actions.RESET_DATI_STORNO}`,
  SET_SALE_DATI_PROMO: `sales/${Actions.SET_SALE_DATI_PROMO}`,

  // LAYBY
  SET_LAYBY_INFO: `sales/${LaybyActions.SET_LAYBY_INFO}`,
  SET_LAYBY_STATUS: `sales/${LaybyActions.SET_LAYBY_STATUS}`,
  RESET_LAYBY_DATA: `sales/${LaybyActions.RESET_LAYBY_DATA}`,
  SET_LAYBY_CONSUMER_LIST: `sales/${LaybyActions.SET_LAYBY_CONSUMER_LIST}`,
  SET_STORE_SALE_LAYBY: `sales/${LaybyActions.SET_STORE_SALE_LAYBY}`,
  RESET_LAYBY: `sales/${LaybyActions.RESET_LAYBY}`,
  RESET_LAYBY_RESUME_DATA: `sales/${LaybyActions.RESET_LAYBY_RESUME_DATA}`,
  SET_CAPI_LAYBY_DELETE: `sales/${LaybyActions.SET_CAPI_LAYBY_DELETE}`,
  REMOVE_CAPI_LAYBY_DELETE: `sales/${LaybyActions.REMOVE_CAPI_LAYBY_DELETE}`,
  LAYBY_RESUME: `sales/${LaybyActions.LAYBY_RESUME}`,
  SET_LAYBY_DEPOSIT_VALID: `sales/${LaybyActions.SET_LAYBY_DEPOSIT_VALID}`,
  REMOVE_LAYBY_CURRENT_SALE: `sales/${LaybyActions.REMOVE_LAYBY_CURRENT_SALE}`,
}

export const SalesGetters = {
  GET_RDV: `sales/${Getters.GET_RDV}`,
  GET_CASHIER: `sales/${Getters.GET_CASHIER}`,
  GET_CONSUMER: `sales/${Getters.GET_CONSUMER}`,
  GET_RDV_ID: `sales/${Getters.GET_RDV_ID}`,
  GET_CASHIER_ID: `sales/${Getters.GET_CASHIER_ID}`,
  GET_ANONYMOUS_ID: `sales/${Getters.GET_ANONYMOUS_ID}`,
  GET_CONSUMER_ID: `sales/${Getters.GET_CONSUMER_ID}`,
  GET_RDV_LABEL: `sales/${Getters.GET_RDV_LABEL}`,
  GET_CASHIER_LABEL: `sales/${Getters.GET_CASHIER_LABEL}`,
  GET_CUSTOMER_LABEL: `sales/${Getters.GET_CUSTOMER_LABEL}`,
  GET_PAYMENTS: `sales/${Getters.GET_PAYMENTS}`,
  GET_ONLY_PAYMENTS: `sales/${Getters.GET_ONLY_PAYMENTS}`,
  GET_PAYMENTS_TOTAL: `sales/${Getters.GET_PAYMENTS_TOTAL}`,
  GET_TOTAL_AMOUNT_CASHED_IN: `sales/${Getters.GET_TOTAL_AMOUNT_CASHED_IN}`,
  GET_DISCOUNT: `sales/${Getters.GET_DISCOUNT}`,
  GET_DISCOUNT_DATA: `sales/${Getters.GET_DISCOUNT_DATA}`,
  GET_LOYALTY_PROGRAM_DISCOUNT: `sales/${Getters.GET_LOYALTY_PROGRAM_DISCOUNT}`,
  GET_DOCUMENTO_SPEDIZIONE: `sales/${Getters.GET_DOCUMENTO_SPEDIZIONE}`,
  GET_REBATE: `sales/${Getters.GET_REBATE}`,
  GET_TAILORING: `sales/${Getters.GET_TAILORING}`,
  GET_PRODUCT_TAILORING: `sales/${Getters.GET_PRODUCT_TAILORING}`,
  GET_EXTRA_TAILORING: `sales/${Getters.GET_EXTRA_TAILORING}`,
  GET_FREE_EXTRA_TAILORING: `sales/${Getters.GET_FREE_EXTRA_TAILORING}`,
  GET_EXTRA_TAILORING_PRICE: `sales/${Getters.GET_EXTRA_TAILORING_PRICE}`,
  GET_TOTAL_PRICE: `sales/${Getters.GET_TOTAL_PRICE}`,
  GET_CAPI_PRICE: `sales/${Getters.GET_CAPI_PRICE}`,
  GET_CAPI: `sales/${Getters.GET_CAPI}`,
  GET_CAPO: `sales/${Getters.GET_CAPO}`,
  CHECK_FOR_ONE_CASH_PAYMENT: `sales/${Getters.CHECK_FOR_ONE_CASH_PAYMENT}`,
  GET_CURRENT_SALE: `sales/${Getters.GET_CURRENT_SALE}`,
  GET_ANONYMOUS: `sales/${Getters.GET_ANONYMOUS}`,
  GET_CURRENT_CAPO_TAILORING: `sales/${Getters.GET_CURRENT_CAPO_TAILORING}`,
  GET_PRODUCT: `sales/${Getters.GET_PRODUCT}`,
  GET_CREDITO_SOSPESO_ENABLED: `sales/${Getters.GET_CREDITO_SOSPESO_ENABLED}`,
  GET_CAPO_ALTERATION: `sales/${Getters.GET_CAPO_ALTERATION}`,
  GET_CAPO_ALTERATION_TOTAL: `sales/${Getters.GET_CAPO_ALTERATION_TOTAL}`,
  GET_SALE_IMPORTO_FINALE: `sales/${Getters.GET_SALE_IMPORTO_FINALE}`,
  GET_CREDIT_CARD_PAYMENT_VALID: `sales/${Getters.GET_CREDIT_CARD_PAYMENT_VALID}`,
  GET_CARDS_PAYMENT_IN_RETURNS_VALID: `sales/${Getters.GET_CARDS_PAYMENT_IN_RETURNS_VALID}`,
  GET_GIFT_CARD_PAYMENT_VALID: `sales/${Getters.GET_GIFT_CARD_PAYMENT_VALID}`,
  GET_COUPON: `sales/${Getters.GET_COUPON}`,
  GET_SALE_STORE_STATUS: `sales/${Getters.GET_SALE_STORE_STATUS}`,
  GET_CART_VIEW: `sales/${Getters.GET_CART_VIEW}`,
  GET_DATA_VISIBILITY: `sales/${Getters.GET_DATA_VISIBILITY}`,
  GET_IS_GIFT_CARD_SAVE_IN_PROCESS: `sales/${Getters.GET_IS_GIFT_CARD_SAVE_IN_PROCESS}`,
  GET_IS_GIFT_CARD_SALE: `sales/${Getters.GET_IS_GIFT_CARD_SALE}`,
  GET_SUSPENDED_CREDIT: `sales/${Getters.GET_SUSPENDED_CREDIT}`,
  CHECK_FOR_ONE_SUSPENDED_CREDIT_PAYMENT: `sales/${Getters.CHECK_FOR_ONE_SUSPENDED_CREDIT_PAYMENT}`,
  GET_SALES_HEADER_ICONS: `sales/${Getters.GET_SALES_HEADER_ICONS}`,
  GET_PAYMENT_DATA: `sales/${Getters.GET_PAYMENT_DATA}`,
  GET_PAYMENT_DATA_CASH: `sales/${Getters.GET_PAYMENT_DATA_CASH}`,
  GET_CART_TYPE: `sales/${Getters.GET_CART_TYPE}`,
  GET_MAX_IMPORT_CHEQUE: `sales/${Getters.GET_MAX_IMPORT_CHEQUE}`,
  GET_SELECTED_CART_TYPE: `sales/${Getters.GET_SELECTED_CART_TYPE}`,
  GET_TOTAL_PRICE_DEPOSIT: `sales/${Getters.GET_TOTAL_PRICE_DEPOSIT}`,
  GET_LAYBY_MINIMUM_DEPOSIT: `sales/${Getters.GET_TOTAL_PRICE_DEPOSIT}`,
  GET_TOTAL_PRICE_DEPOSIT_PAYMENT_METHOD: `sales/${Getters.GET_TOTAL_PRICE_DEPOSIT_PAYMENT_METHOD}`,
  GET_IS_GROUP_CONSUMER: `sales/${Getters.GET_IS_GROUP_CONSUMER}`,
  GET_USED_CASH: `sales/${Getters.GET_USED_CASH}`,
  GET_SUM_OF_DEBTPAYMENTS_VALIDATION_GIFTCARDSALE: `sales/${Getters.GET_SUM_OF_DEBTPAYMENTS_VALIDATION_GIFTCARDSALE}`,
  IS_FREE_RETURN: `sales/${Getters.IS_FREE_RETURN}`,
  IS_ALL_FREE_RETURN: `sales/${Getters.IS_ALL_FREE_RETURN}`,
  GET_CODICE_MOVIMENTO: `sales/${Getters.GET_CODICE_MOVIMENTO}`,
  GET_DATI_STORNO: `sales/${Getters.GET_DATI_STORNO}`,
  GET_DISABLED_CONFIRM: `sales/${Getters.GET_DISABLED_CONFIRM}`,
  GET_CAN_CHANGE_CART_TYPE: `sales/${Getters.GET_CAN_CHANGE_CART_TYPE}`,
  GET_ALL_PRODUCT: `sales/${Getters.GET_ALL_PRODUCT}`,
  IS_CASH_AMOUNT_ABOVE_MAXPAYABLE: `sales/${Getters.IS_CASH_AMOUNT_ABOVE_MAXPAYABLE}`,
  GET_CHECK_ANTIRICICLAGGIO_RESULT: `sales/${Getters.GET_CHECK_ANTIRICICLAGGIO_RESULT}`,
  GET_INFO_GIACENZA_CAPI: `sales/${Getters.GET_INFO_GIACENZA_CAPI}`,
  GET_QUANTITY_INFO_GIACENZA_CAPI: `sales/${Getters.GET_QUANTITY_INFO_GIACENZA_CAPI}`,
  IS_ONLY_TAILORING_ALTERATION_FREE_SALE: `sales/${Getters.IS_ONLY_TAILORING_ALTERATION_FREE_SALE}`,
  IS_ONLY_FREE_SALE: `sales/${Getters.IS_ONLY_FREE_SALE}`,
  IS_OSS_SALE: `sales/${Getters.IS_OSS_SALE}`,
  GET_CONSUMER_SAME_COUNTRY_CONDE: `sales/${Getters.GET_CONSUMER_SAME_COUNTRY_CONDE}`,
  PURCHASE_IS_ABOVE_THRESHOLD: `sales/${Getters.PURCHASE_IS_ABOVE_THRESHOLD}`,
  GET_DOCUMENTO_SPEDIZIONE_CONSUMER: `sales/${Getters.GET_DOCUMENTO_SPEDIZIONE_CONSUMER}`,
  GET_DOCUMENTO_SPEDIZIONE_STORE: `sales/${Getters.GET_DOCUMENTO_SPEDIZIONE_STORE}`,
  GET_SHOPPING_BAGS: `sales/${Getters.GET_SHOPPING_BAGS}`,
  GET_SHIPPING_COST: `sales/${Getters.GET_SHIPPING_COST}`,
  GET_TAX_AMOUNT: `sales/${Getters.GET_TAX_AMOUNT}`,
  // LAYBY
  GET_LAYBY_INFO: `sales/${LaybyGetters.GET_LAYBY_INFO}`,
  GET_LAYBY_STATUS: `sales/${LaybyGetters.GET_LAYBY_STATUS}`,
  GET_IS_DEPOSIT_FLOW: `sales/${LaybyGetters.GET_IS_DEPOSIT_FLOW}`,
  GET_LAYBY_STATUS_EQUAL_TO: `sales/${LaybyGetters.GET_LAYBY_STATUS_EQUAL_TO}`,
  GET_LAYBY_CONSUMER_LIST: `sales/${LaybyGetters.GET_LAYBY_CONSUMER_LIST}`,
  GET_LAYBY_RESUME_DATA: `sales/${LaybyGetters.GET_LAYBY_RESUME_DATA}`,
  GET_LAYBY_DEPOSIT_VALID: `sales/${LaybyGetters.GET_LAYBY_DEPOSIT_VALID}`,
  GET_LAYBY_CALC_MINIMUM_DEPOSIT: `sales/${LaybyGetters.GET_LAYBY_CALC_MINIMUM_DEPOSIT}`,
}

export const SalesMutations = {
  SET_MODAL_CREDIT_CARD_INSTANCE: `sales/${Mutations.SET_MODAL_CREDIT_CARD_INSTANCE}`,
  CLEAR_CASHIER: `sales/${Mutations.CLEAR_CASHIER}`,
  CLEAR_CONSUMER: `sales/${Mutations.CLEAR_CONSUMER}`,
  CLEAR_ANONYMOUS_FLAG: `sales/${Mutations.CLEAR_ANONYMOUS_FLAG}`,
  CLEAR_RDV: `sales/${Mutations.CLEAR_RDV}`,
  UPDATE_PRODUCT_DETAIL: `sales/${Mutations.UPDATE_PRODUCT_DETAIL}`,
  STORE_CART_VIEW: `sales/${Mutations.STORE_CART_VIEW}`,
  STORE_PRODUCTS: `sales/${Mutations.STORE_PRODUCTS}`,
  STORE_SALE_PAGAMENTI: `sales/${Mutations.STORE_SALE_PAGAMENTI}`,
}
