import store, { RootState } from '@/store'
import { formatNumero, roundDecimals } from '@/addons/adjustments'
import { isConfigTruthy } from '@/addons/functions/configs'
import {
  catalogApi,
  consumerDataApi,
  couponsApi,
  giftCardApi,
  salesApi,
} from '@/addons/axios'
import {
  AnonymousConsumers,
  CodiceStatoVendita,
  LaybyStatusType,
  PaymentKeys,
  PageContexts,
  SessionKeys,
  TipiDocumento,
  TipiMovimenti,
  TipiVendita,
  ShipDestinationType,
} from '@/addons/enums'
import i18n from '@/addons/i18n'
import {
  ApiV1CouponsCouponCodeCheckDataAttributes,
  ApiV1RefundsB2eSaleIdPaymentsPosPosTransactionIdDataAttributesTipoStornoEnum as TipoStornoEnum,
  ApiV1SalesCheckDatiAntiriciclaggioDataAttributes,
  ApiV1SalesData,
  ApiV1SalesDataAttributes,
  ApiV1SalesDataAttributesCapi,
  ApiV1SalesDataAttributesPagamenti,
  ApiV1SalesSaleIdDataAttributes,
  ApiV1SalesSaleIdDataAttributesCapi,
  ApiV1SalesSaleIdDataAttributesPagamenti,
  BurnGiftCard,
  CouponData,
  GetCashiersAttributes,
  GetConsumerAttributes,
  GetCountriesAttributes,
  GetSkuDataAttributes,
  GetSkuDataAttributesListiniPriceType,
  GiftCardData,
  GiftCardData1DataAttributes,
  GiftCardInformationDataAttributes,
  GiftCards,
  ManageSaleReturnData,
  PatchSale,
  PostCoupon1,
  PostSale,
  ReturnBurnGiftCard,
  Sale,
} from '@/api'
import modalController, { ModalTypes } from '@/components/modal/ModalController'
import {
  FatturaAmbForm,
  FatturaForm,
  FatturaTaxfreeForm,
  FatturaUEForm,
} from '@/components/recap/fattura/fatturaModels'
import {
  AlterationsTab,
  AlterationsTabItem,
  GetAlterationPricesDataAttributes,
} from '@/interfaces/tailoring'
import router from '@/router'
import { ConfigGetters } from '@/store/configs-store'
import { GenericActions, GenericGetters } from '@/store/generic-store'
import { LoyaltyGetters, LoyaltyActions } from '@/modules/loyalty/store/index'
import { AxiosError, AxiosResponse } from 'axios'
import { addMinutes, format } from 'date-fns'
import { ActionContext } from 'vuex'

import { NotificationsActions } from '../notifications-store'
import {
  extractErrorMessage,
  getNewTransactionId,
  getFrontendApplicationType,
} from '@/addons/functions'
import LoaderController from '@/components/loader/LoaderController'
import { PaymentsMutations } from '../payments-store'
import { AuthGetters } from '@/store/auth'
import { GiftCardForm } from '@/interfaces/gift-card'
import { SaleTicketDocument } from '@/interfaces/recap-documents'
import { SuspendedCreditForm } from '@/components/sales/payments/payment-suspended-credit/suspendedCredit'

import {
  dateToISODayWithTime,
  unformatMMFGDate,
  ISO8601DateTimeStandard,
} from '@/addons/date'
import { PaymentMethodForm } from '@/interfaces/payment-method-form'

import PrintErrorHandler, {
  ErrorDisplayTypes,
} from '@/addons/functions/printErrorHandler'
import { GiftMutations } from '../gift-store'
import { ConsumersActions, ConsumersGetters } from '@/store/consumer'
import { ErrorD } from '@/addons/interface'
import { openModalTailoringAlert } from '@/components/gestione-tessuto/modal'
import { ABSTRID_EA } from '@/configs'
import {
  ActionsNames,
  AddressValidationActions,
  AddressValidationGetters,
} from '@/modules/address-validation/store'

import {
  addToCartEvent,
  changeCustomerEvent,
  completePurchaseEvent,
  Product,
} from '@/abs-core/shared/lib/core-events/events-api'
import {
  Actions,
  CanChangeCartTypeD,
  CartType,
  datiStornoD,
  Getters,
  InfoGiacenzaCapiD,
  Mutations,
  SalesState,
  Statistics,
  StoreConsumerPayload,
  UsedCash,
} from './sales-types'
import {
  Actions as LaybyActions,
  Mutations as LaybyMutations,
} from './layby/sales-lb-types'
import { SalesActions, SalesGetters, SalesMutations } from './sales-store'
import { FeLogger } from '@/addons/monitoring'

export const salesActions = {
  [Actions.OPEN_CREDIT_CARD_MODAL]: (
    context: ActionContext<SalesState, RootState>,
  ): Promise<TipoStornoEnum | undefined> => {
    return new Promise((resolve) => {
      const instance = modalController.open({
        type: ModalTypes.INFO,
        component: 'FeModalCreditCard',
        title: i18n.global.t('pos_common.attention'),
        message: i18n.global.t('pos_returns.modal_credit_card_message'),
        confirmActionButton: false,
        customFooter: true,
        customClass: 'fe-custom-modal-returns',
        customProps: {
          refundType: 'DELETELAYBY',
        },
        customEventHandlers: {
          returnTransaction: () => resolve(TipoStornoEnum.Storno),
          creditAmount: () => resolve(TipoStornoEnum.Accredito),
        },
        closedAction: () => resolve(undefined),
      })
      context.commit(Mutations.SET_MODAL_CREDIT_CARD_INSTANCE, instance)
    })
  },
  [Actions.CLOSE_CREDIT_CARD_MODAL]: (
    context: ActionContext<SalesState, RootState>,
  ): void => {
    if (context.state.modalCreditCardInstance) {
      context.state.modalCreditCardInstance.close()
      context.commit(Mutations.SET_MODAL_CREDIT_CARD_INSTANCE, undefined)
    }
  },
  [Actions.RESET_STATE]: (
    context: ActionContext<SalesState, RootState>,
  ): void => {
    context.commit(Mutations.RESET_STATE)
  },
  [Actions.RESET_DATI_STORNO]: (
    context: ActionContext<SalesState, RootState>,
  ): void => {
    context.commit(Mutations.RESET_DATI_STORNO)
  },
  [Actions.INIT_SALE]: (
    context: ActionContext<SalesState, RootState>,
  ): void => {
    const sid = store.getters[AuthGetters.GET_SID]
    context.commit(Mutations.STORE_SALE, {
      pk_consumer: '',
      sid: sid,
      tipo_applicazione_apertura: getFrontendApplicationType(),
      tipo_applicazione_chiusura: getFrontendApplicationType(),
      cod_cassa: context.rootState.configs.setup?.cash_id,
      cod_documento: '',
      cod_negozio: context.rootState.configs.currentStore?.STORE_CODE,
      codice_movimento: TipiVendita.VENDITA,
      codice_stato: 'CURRENT',
      dati_aggiuntivi: '',
      dati_documenti: '{}',
      divisa: context.rootState.configs.currentStore?.STORE_SIGN,
      flag_stampa_documento: 0,
      flag_stampa_scontrino_cortesia: 0,
      id_postazione_apertura: context.rootState.configs.setup?.cash_id,
      id_postazione_chiusura: context.rootState.configs.setup?.cash_id,
      id_transazione: undefined,
      importo_finale: 0,
      importo_iniziale: 0,
      importo_pagato: 0,
      nota: '',
      capi: [],
      pagamenti: [],
      data_creazione: undefined,
    } as ApiV1SalesDataAttributes)
    context.commit(Mutations.CLEAR_ANONYMOUS_FLAG)
    context.commit(Mutations.CLEAR_CONSUMER)
    context.commit(Mutations.CLEAR_CASHIER)
    context.commit(Mutations.RESET_CART_TYPE)
    context.commit(Mutations.CLEAR_RDV)
    context.commit(Mutations.CLEAR_PRODUCTS)
    context.commit(Mutations.CLEAR_PROGRESSIVO_PAGAMENTI)
    context.commit(Mutations.CLEAR_PROGRESSIVO_CAPO)
    context.commit(Mutations.CLEAR_USED_CASH)
    context.commit(LaybyMutations.RESET_LAYBY)
    context.dispatch(LaybyActions.RESET_LAYBY_DATA)
    context.commit(LaybyMutations.RESET_LAYBY_RESUME_DATA)
    context.commit(Mutations.RESET_DATI_STORNO)
    store.dispatch(ConsumersActions.RESET_CURRENT_CONSUMER)
    store.dispatch(ConsumersActions.RESET_STATE)
    store.commit(PaymentsMutations.CLEAR_VALIDATION_PAGAMENTI)
    sessionStorage.removeItem(SessionKeys.CURRENT_SALE)
  },
  [Actions.SAVE_SALE]: (
    context: ActionContext<SalesState, RootState>,
  ): void => {
    if (context.state.currentSale.id_transazione) {
      context.dispatch(Actions.UPDATE_SALE)
    } else {
      context.dispatch(Actions.CREATE_SALE)
    }
  },
  /**
   * Asynchronously creates a new sale in the system.
   *
   * This action is responsible for handling the creation of a new sale. It performs the following steps:
   * 1. Checks if the current sale has a `pk_consumer` property, and if not, it retrieves the current consumer from the store and assigns it to the `pk_consumer` property.
   * 2. Dispatches the `Actions.COMPUTE_TOTALS` action to calculate the totals for the current sale.
   * 3. Sets the `codice_stato` property of the current sale to 'CLOSED'.
   * 4. Maps over the `capi` array of the current sale, setting the `codice_stato` property to 'CLOSED' and removing the `layby_delete` and `error_code` properties if they exist.
   * 5. Shows the loader with the `PageContexts.FRONTOFFICE` section.
   * 6. Calls the `salesApi.apiV1SalesPost` method to create the new sale, passing the `saleData` object as the request payload.
   * 7. Handles the response from the API call, displaying any errors, hiding the loader, initializing a new sale, resetting the loyalty state if applicable, and dispatching a success notification.
   * 8. If an error occurs during the sale creation, it updates the `codice_stato` property of the current sale to 'CURRENT', displays the error message(s), and hides the loader.
   * 9. Returns a boolean value indicating whether the sale creation was successful or not.
   *
   * @param {ActionContext<SalesState, RootState>} context - The Vuex action context.
   * @returns {Promise<boolean>} - A promise that resolves to a boolean value indicating whether the sale creation was successful or not.
   */
  [Actions.CREATE_SALE]: async (
    context: ActionContext<SalesState, RootState>,
  ) => {
    let isSaleOk
    const saleData = context.state.currentSale
    if (context.state.currentSale && !context.state.currentSale.pk_consumer) {
      const savedSale = JSON.parse(
        sessionStorage.getItem(SessionKeys.CURRENT_SALE) || '{}',
      )
      const getConsumer = store.getters[ConsumersGetters.GET_CURRENT_CONSUMER]
      context.state.currentSale.pk_consumer =
        getConsumer?.pk_consumer || savedSale?.pk_consumer || null
    }
    await context.dispatch(Actions.COMPUTE_TOTALS)
    // eslint-disable-next-line require-atomic-updates
    saleData.codice_stato = 'CLOSED'
    saleData.capi?.map((d) => {
      d.codice_stato = 'CLOSED'
      if ('layby_delete' in d) {
        delete d.layby_delete
      }
      if ('error_code' in d) {
        delete d.error_code
      }
      return d
    })

    LoaderController.show({
      section: PageContexts.FRONTOFFICE,
    })

    return salesApi
      .apiV1SalesPost({
        data: {
          type: 'sale',
          attributes: saleData,
        },
        sale_origin: ABSTRID_EA,
        preserve_decimals: true,
      })
      .then(
        async (resp) => {
          await PrintErrorHandler(
            resp.data.data,
            false,
            ErrorDisplayTypes.MODAL,
          )
          LoaderController.hide()
          await context.dispatch(Actions.INIT_SALE)
          if (store.getters[LoyaltyGetters.IS_LOYALTY_ACTIVE]) {
            await store.dispatch(
              LoyaltyActions.RESET_CURRENT_SALE_FIDELITY_POINTS,
            )
            await store.dispatch(LoyaltyActions.RESET_STATE)
          }
          context.dispatch(
            NotificationsActions.NOTIFY_SUCCESS,
            'pos_sale.sale_success',
            { root: true },
          )
          router.go(-1)
          sessionStorage.removeItem(SessionKeys.LAYBY_RESUME)
          isSaleOk = true
        },
        (error) => {
          context.state.currentSale.codice_stato = 'CURRENT'
          if (error?.response?.data?.errors?.length) {
            let errorMessage = error?.response?.data?.errors[0].detail
            try {
              let errorDetail = errorMessage
              if (errorDetail.indexOf('{') !== -1) {
                // eslint-disable-next-line quotes
                errorDetail = errorDetail.replaceAll("'", '"')
              }
              const parseError = JSON.parse(errorDetail)
              errorMessage = Object.entries(parseError).map((el) => {
                const [, v] = el
                return v
              })
            } catch (e) {
              errorMessage = error?.response?.data?.errors[0].detail
            }
            if (typeof errorMessage === 'string') {
              context.dispatch(
                NotificationsActions.NOTIFY_ERROR,
                i18n.global.t('pos_sale.sale_error') +
                  (errorMessage ? ': \n' + errorMessage : ''),
                { root: true },
              )
            } else {
              errorMessage.forEach((m) => {
                context.dispatch(
                  NotificationsActions.NOTIFY_ERROR,
                  i18n.global.t('pos_sale.sale_error') + (m ? ': \n' + m : ''),
                  { root: true },
                )
              })
            }
          }
          isSaleOk = false
          LoaderController.hide()
          return isSaleOk
        },
      )
  },
  /**
   * Updates the current sale in the application state.
   *
   * This function is responsible for updating the current sale in the application state. It performs the following steps:
   * 1. Shows a loader to indicate that the update is in progress.
   * 2. Computes the totals for the current sale.
   * 3. Retrieves the current sale data from the application state.
   * 4. Updates the sale status to "CLOSED".
   * 5. Enhances the "capi" (items) in the sale data by removing any "error_code" properties.
   * 6. Sends a PATCH request to the sales API to update the sale.
   * 7. Handles any errors that occur during the update process.
   * 8. Resets the loyalty state if loyalty is active.
   * 9. Initializes a new sale.
   * 10. Displays a success notification.
   * 11. Navigates back to the previous page.
   * 12. Returns a boolean indicating whether the update was successful.
   *
   * @param context - The action context, which provides access to the application state and dispatch function.
   * @returns A Promise that resolves to a boolean indicating whether the update was successful.
   */
  [Actions.UPDATE_SALE]: async (
    context: ActionContext<SalesState, RootState>,
  ): Promise<boolean> => {
    LoaderController.show({
      section: PageContexts.DOCUMENTS,
    })
    await context.dispatch(Actions.COMPUTE_TOTALS)
    const saleData = context.state.currentSale
    try {
      const id = `${saleData.cod_negozio},${saleData.id_transazione}`
      context.commit(Mutations.UPDATE_SALE_STATUS, CodiceStatoVendita.CLOSED)

      if (saleData.capi?.length > 0) {
        const enhancedCapi = saleData.capi?.map((c) => {
          if ('error_code' in c) {
            delete c.error_code
          }
          return {
            ...c,
          }
        })

        saleData.capi = enhancedCapi
      }

      const resp = await salesApi.apiV1SalesSaleIdPatch(id, {
        data: {
          id,
          type: 'sale',
          attributes: saleData,
        },
        preserve_decimals: true,
      })
      await PrintErrorHandler(resp.data.data, false, ErrorDisplayTypes.MODAL)
      LoaderController.hide()

      if (store.getters[LoyaltyGetters.IS_LOYALTY_ACTIVE]) {
        await store.dispatch(LoyaltyActions.RESET_STATE)
      }

      await context.dispatch(Actions.INIT_SALE)
      context.dispatch(
        NotificationsActions.NOTIFY_SUCCESS,
        'pos_sale.sale_success',
        { root: true },
      )
      router.go(-1)
      return true
    } catch (error) {
      let errorMessage = 'pos_sale.sale_error'

      if (error?.response?.data?.errors?.length) {
        const details = error?.response?.data?.errors[0].detail || ''
        if (details) {
          errorMessage = details
        }
      }

      context.dispatch(NotificationsActions.NOTIFY_ERROR, errorMessage, {
        root: true,
      })

      LoaderController.hide()
      return false
    }
  },
  /**
   * Hydrates the sales state with the provided sale data.
   * This function is responsible for:
   * - Storing the sale data in the state
   * - Fetching and storing the associated cashier data
   * - Handling anonymous consumers
   * - Fetching and storing the associated consumer data
   * - Hydrating the sales SKUs
   * - Handling payments and coupons
   * - Computing the sale totals
   * - Restoring a previously suspended sale
   *
   * @param context - The Vuex action context
   * @param sale - The sale data to hydrate the state with
   */
  [Actions.HYDRATE_SALES]: async (
    context: ActionContext<SalesState, RootState>,
    sale: ApiV1SalesDataAttributes | ApiV1SalesSaleIdDataAttributes,
  ): Promise<void> => {
    context.commit(Mutations.STORE_SALE, {
      ...sale,
      pagamenti: [],
      capi: [],
      id_postazione_chiusura:
        context.rootState.configs.setup?.cash_id || sale.id_postazione_chiusura,
      tipo_applicazione_chiusura: getFrontendApplicationType(),
    } as ApiV1SalesDataAttributes)

    if (sale.cod_cassiera) {
      const cashiers =
        store.state.cashiers.cashiers.filter(
          (c: GetCashiersAttributes) => c.venditrice === sale.cod_cassiera,
        ) || []
      const cashier = cashiers[0]
      context.commit(Mutations.STORE_CASHIER, cashier)
      context.commit(Mutations.STORE_RDV, cashier)
    }

    const matchingAnonymous = store.state.consumers.anonymousConsumer.filter(
      (ac) => ac.pk_consumer === sale.pk_consumer,
    )
    const isAnonymous = matchingAnonymous.length > 0

    if (isAnonymous) {
      await store.dispatch(SalesActions.SET_ANONYMOUS, true)
      await context.dispatch(
        Actions.SET_ANONYMOUS_CONSUMER,
        matchingAnonymous.shift()?.pk_consumer,
      )
    } else {
      // If the sale we are hydrating has a consumer linked, we have to fetch consumer data
      if (sale.pk_consumer) {
        const consumerResponse = await consumerDataApi.apiV1ConsumersGet(
          'local',
          undefined,
          undefined,
          undefined,
          undefined,
          undefined,
          undefined,
          undefined,
          undefined,
          undefined,
          undefined,
          undefined,
          undefined,
          {
            params: {
              'filter[pk_consumer_search]': sale.pk_consumer,
            },
          },
        )
        const consumer = consumerResponse.data.data?.shift()

        FeLogger.info(
          '[StoreSales.Actions] HYDRATE_SALES consumer: ' +
            consumer?.attributes?.pk_consumer,
        )

        if (consumer) {
          context.dispatch(Actions.SET_CONSUMER, {
            consumer: consumer.attributes,
          })
        }

        if (isConfigTruthy('CARD_ENABLED')) {
          store.dispatch(LoyaltyActions.SET_LOYALTY_CARDS, sale.pk_consumer)
        }
      }
    }

    // If we the sale we are hydrating had some products in it, we have to add those SKUs.

    if (sale.capi?.length) {
      await context.dispatch(Actions.HYDRATE_SALES_SKUS, sale.capi)
    }

    if (sale.pagamenti?.length) {
      sale.pagamenti.forEach((p) => {
        // Update current progressive id so that it is kept in sync with what the suspended sale said.
        context.commit(Mutations.STORE_PROGRESSIVO_PAGAMENTI, p.progressivo)
        // Add payment as described by the received sale
        context.dispatch(Actions.ADD_PAYMENT, p)
        // Make sure to update the validation part
        store.commit(PaymentsMutations.STORE_VALIDATION_PAGAMENTI, {
          ...store.state.payments.payments_validator,
          [p.progressivo as number]: true,
        })
      })
      if (context.getters[Getters.GET_CREDIT_CARD_PAYMENT_VALID]) {
        context.dispatch(GenericActions.OPEN_PAYMENTS, undefined, {
          root: true,
        })
      }
      if (context.getters[Getters.GET_COUPON]) {
        context.dispatch(Actions.VALIDATE_COUPON)
      }
    }
    await context.dispatch(Actions.COMPUTE_TOTALS)
    // If we are handling a previously suspended sale, we have to inform APIs that we are restoring that sale
    if (
      typeof sale.id_transazione !== 'undefined' &&
      context.state.currentSale.codice_stato !== 'CURRENT'
    ) {
      context.state.currentSale.codice_stato = 'CURRENT'

      context.state.currentSale.capi?.forEach((c) => {
        c.codice_stato = 'CURRENT'
      })

      const id = `${sale.cod_negozio},${sale.id_transazione}`
      const saleTrace: PatchSale | PostSale = {
        data: {
          id,
          type: 'sales',
          attributes: {
            ...sale,
            codice_stato: 'CURRENT',
          },
        },
      }
      try {
        const response = await salesApi.apiV1SalesSaleIdPatch(
          id as string,
          saleTrace,
        )
        const documents = JSON.parse(
          response.data.data?.attributes?.dati_documenti ?? '{}',
        )
        context.dispatch(Actions.UPDATE_DATI_DOCUMENTI, documents ?? '{}')

        if (Object.keys(documents).length > 0) {
          store.dispatch(
            AddressValidationActions[ActionsNames.SET_ADDRESS_TYPE],
            documents.shipping_destination,
          )
          store.dispatch(AddressValidationActions['set-selected-addres'], {
            ...context.getters[
              AddressValidationGetters['get-selected-address']
            ],
            shipping_destination: documents.shipping_destination,
            name:
              documents.shipping_destination === ShipDestinationType.CONSUMER
                ? documents.shipping_address?.name
                : documents.shipping_address.consumer_info?.name,
            surname:
              documents.shipping_destination === ShipDestinationType.CONSUMER
                ? documents.shipping_address?.surname
                : documents.shipping_address.consumer_info?.surname,
            country:
              documents.shipping_destination === ShipDestinationType.CONSUMER
                ? documents.shipping_address?.country
                : documents.shipping_address.consumer_info?.country,
            building:
              documents.shipping_destination === ShipDestinationType.CONSUMER
                ? documents.shipping_address?.building
                : documents.shipping_address.consumer_info?.building,
            address:
              documents.shipping_destination === ShipDestinationType.CONSUMER
                ? (documents.shipping_address.consumer_info?.address_raw ??
                  documents.shipping_address?.consumer_info?.address)
                : documents.shipping_address.consumer_info?.address,
            street_number:
              documents.shipping_destination === ShipDestinationType.CONSUMER
                ? documents.shipping_address?.street_number
                : documents.shipping_address.consumer_info?.street_number,
            city:
              documents.shipping_destination === ShipDestinationType.CONSUMER
                ? documents.shipping_address?.city
                : documents.shipping_address?.consumer_info?.city,
            zipcode:
              documents.shipping_destination === ShipDestinationType.CONSUMER
                ? documents.shipping_address?.zipcode
                : documents.shipping_address.consumer_info?.zipcode,
            province:
              documents.shipping_destination === ShipDestinationType.CONSUMER
                ? documents.shipping_address?.province
                : documents.shipping_address.consumer_info?.province,
            state:
              documents.shipping_destination === ShipDestinationType.CONSUMER
                ? documents.shipping_address?.state
                : documents.shipping_address.consumer_info?.state,
            contact_email:
              documents.shipping_destination === ShipDestinationType.CONSUMER
                ? documents.shipping_address?.contact_email
                : documents.shipping_address.consumer_info?.contact_email,
            contact_number:
              documents.shipping_destination === ShipDestinationType.CONSUMER
                ? documents.shipping_address?.contact_number
                : documents.shipping_address?.consumer_info?.contact_number,
            address_notes:
              documents.shipping_destination === ShipDestinationType.CONSUMER
                ? documents.shipping_address?.address_notes
                : (documents.shipping_address?.consumer_info?.address_notes ??
                  documents.shipping_address?.consumer_info?.reference_name),
            contact_note:
              documents.shipping_destination === ShipDestinationType.CONSUMER
                ? documents.shipping_address?.contact_note
                : documents.shipping_address?.consumer_info?.contact_note,
          })
        }
      } catch (err) {
        context.dispatch(
          NotificationsActions.NOTIFY_ERROR,
          'pos_sale.error_restoring_sale',
          { root: true },
        )
      }
    }
  },
  [Actions.CHECK_TRANSACTION]: async (
    context: ActionContext<SalesState, RootState>,
  ): Promise<bool> => {
    try {
      const sale = context.state.currentSale
      sale.capi?.map((d) => {
        if ('error_code' in d) {
          delete d.error_code
        }
      })
      if (
        Object.keys(sale).includes('id_transazione') &&
        typeof sale.id_transazione !== 'undefined'
      ) {
        const id = `${sale.cod_negozio},${sale.id_transazione}`
        const resp = await salesApi.apiV1SalesSaleIdCheckTransactionPatch(id, {
          data: {
            id,
            type: 'sale',
            attributes: context.state.currentSale,
          },
          preserve_decimals: true,
        })
        if (resp.data?.errors?.length > 0) {
          resp.data?.errors.forEach((el) => {
            context.dispatch(NotificationsActions.NOTIFY_ERROR, el.detail, {
              root: true,
            })
          })
          return false
        }
        return true
      }
      return true
    } catch (error) {
      const errorResponse = error as ErrorD
      const errorMessage = errorResponse?.response?.data?.errors?.length
        ? extractErrorMessage(
            errorResponse?.response?.data?.errors[0]?.detail ?? '',
          )
        : 'pos_sale.sale_error'
      context.dispatch(NotificationsActions.NOTIFY_ERROR, errorMessage, {
        root: true,
      })
      return false
    }
  },
  [Actions.HYDRATE_SALES_SKUS]: async (
    context: ActionContext<SalesState, RootState>,
    capi: ApiV1SalesDataAttributesCapi[] | ApiV1SalesSaleIdDataAttributesCapi[],
  ): Promise<void> => {
    const max_progress = Math.max(...capi.map((c) => c.progressivo ?? 0))
    await capi.reduce((promise, c) => {
      // Make sure to update the product progressive id so that the added
      // product and the saved one have the same id.
      return promise.then(() => context.dispatch(Actions.ADD_SKU, c))
    }, Promise.resolve())
    context.commit(Mutations.STORE_PROGRESSIVO_CAPO, max_progress)
  },
  [Actions.SUSPEND_SALE]: async (
    context: ActionContext<SalesState, RootState>,
    preserveSale = false,
  ): Promise<AxiosResponse<Sale> | undefined> => {
    const sale = context.state.currentSale
    const isSuspendedSale = typeof sale.id_transazione !== 'undefined'
    const id = isSuspendedSale
      ? `${sale.cod_negozio},${sale.id_transazione}`
      : undefined
    if (!preserveSale) {
      const pagamenti = context.state.currentSale
        .pagamenti as Array<ApiV1SalesDataAttributesPagamenti>
      const promises = pagamenti.map((p) => {
        if (
          p?.progressivo &&
          !store.state.payments.payments_validator[p.progressivo]
        ) {
          return store.dispatch(SalesActions.REMOVE_PAYMENT, p.progressivo)
        }
        return Promise.resolve()
      })
      await Promise.all(promises)
    }
    // Make sure to update totals before actually submitting the updated sale
    await context.dispatch(Actions.COMPUTE_TOTALS)
    context.commit(
      Mutations.UPDATE_SALE_STATUS,
      preserveSale ? CodiceStatoVendita.CURRENT : CodiceStatoVendita.SUSPENDED,
    )
    // each capo needs to have the id_transazione field set to the id of the sale
    const enhancedCapi = context.state.currentSale.capi?.map((c) => {
      if ('layby_delete' in c) {
        delete c.layby_delete
      }
      if ('error_code' in c) {
        delete c.error_code
      }
      return {
        ...c,
        id_transazione: sale.id_transazione,
      }
    })
    const saleTrace: PatchSale = {
      preserve_decimals: true,
      sale_origin: ABSTRID_EA,
      data: {
        id,
        type: 'sales',
        attributes: {
          ...sale,
          capi: enhancedCapi,
        },
      },
    }
    try {
      let result: AxiosResponse<Sale>

      if (isSuspendedSale) {
        result = await salesApi.apiV1SalesSaleIdPatch(id as string, saleTrace)
      } else {
        result = await salesApi.apiV1SalesPost(saleTrace)
      }

      if (!preserveSale) {
        context.dispatch(Actions.INIT_SALE)
        context.dispatch(
          NotificationsActions.NOTIFY_SUCCESS,
          'pos_sale.successfully_suspended_sale',
          { root: true },
        )
      } else {
        if (result.data.data?.attributes?.id_transazione) {
          context.commit(
            Mutations.STORE_ID_TRANSAZIONE,
            result.data.data.attributes.id_transazione,
          )
        }
        if (result.data.data?.attributes?.data_creazione) {
          context.commit(
            Mutations.STORE_CREATION_DATE,
            result.data.data.attributes.data_creazione,
          )
        }
        return result
      }
    } catch (rawError) {
      const error = rawError as AxiosError

      const errorMessage = error?.response?.data?.errors?.length
        ? extractErrorMessage(error?.response?.data?.errors[0]?.detail)
        : ''

      const msg = i18n.global.t('pos_sale.error_suspending_sale')
      const toShow = errorMessage ? `${msg}\n${errorMessage}` : msg

      context.dispatch(NotificationsActions.NOTIFY_ERROR, toShow, {
        root: true,
      })
    }
  },
  [Actions.TRY_PAYMENT_CREDIT_CARD]: async (
    context: ActionContext<SalesState, RootState>,
  ): Promise<AxiosResponse<Sale> | undefined> => {
    const sale = context.state.currentSale
    const isSuspendedSale = typeof sale.id_transazione !== 'undefined'
    const id = isSuspendedSale
      ? `${sale.cod_negozio},${sale.id_transazione}`
      : undefined
    await context.dispatch(Actions.COMPUTE_TOTALS)
    context.commit(Mutations.UPDATE_SALE_STATUS, CodiceStatoVendita.CURRENT)
    const enhancedCapi = context.state.currentSale.capi?.map((c) => {
      if ('layby_delete' in c) {
        delete c.layby_delete
      }
      if ('error_code' in c) {
        delete c.error_code
      }
      const dataCapi = { ...c, id_transazione: sale.id_transazione }
      return dataCapi
    })
    const saleTrace: PatchSale = {
      preserve_decimals: true,
      data: {
        id,
        type: 'sales',
        attributes: {
          ...sale,
          capi: enhancedCapi,
        },
      },
    }
    try {
      let result: AxiosResponse<Sale>

      if (isSuspendedSale) {
        result = await salesApi.apiV1SalesSaleIdPatch(id as string, saleTrace)
      } else {
        result = await salesApi.apiV1SalesPost(saleTrace)
      }
      if (result.data.data?.attributes?.id_transazione) {
        context.commit(
          Mutations.STORE_ID_TRANSAZIONE,
          result.data.data.attributes.id_transazione,
        )
      }
      if (result.data.data?.attributes?.data_creazione) {
        context.commit(
          Mutations.STORE_CREATION_DATE,
          result.data.data.attributes.data_creazione,
        )
      }
      return result
    } catch (rawError) {
      const error = rawError as AxiosError

      const errorMessage = error?.response?.data?.errors?.length
        ? extractErrorMessage(error?.response?.data?.errors[0]?.detail)
        : ''

      const msg = i18n.global.t('pos_common.error')
      const toShow = errorMessage ? `${msg}\n${errorMessage}` : msg

      context.dispatch(NotificationsActions.NOTIFY_ERROR, toShow, {
        root: true,
      })
    }
  },
  /**
   * Adds a new capo (product) to the current sale in the Vuex store.
   *
   * This action is responsible for creating a new capo object with the necessary data, including:
   * - Combining the attributes from the `modelDetails.data.data[0].attributes` object with the `rfid` property.
   * - Checking if there are any free alterations with textile-only items in the cart and opening a modal to alert the user if necessary.
   * - Generating a unique `progressivo_capi` value for the new capo.
   * - Updating the `product_detail` object in the store with the new capo's details.
   * - Committing the new capo to the `currentSale.capi` array in the store.
   * - Dispatching actions to validate the coupon, confirm discounts, compute totals, and check product stock.
   *
   * @param {ActionContext<SalesState, RootState>} context - The Vuex action context.
   * @param {ApiV1SalesDataAttributesCapi | ApiV1SalesSaleIdDataAttributesCapi} capo - The capo (product) data to be added.
   * @returns {Promise<void>} - A Promise that resolves when the action is complete.
   */
  [Actions.ADD_SKU]: async (
    context: ActionContext<SalesState, RootState>,
    payload: ApiV1SalesDataAttributesCapi | ApiV1SalesSaleIdDataAttributesCapi,
  ): Promise<boolean> => {
    // Try to do things using what we just read, eventually falling back to sku field.
    const sku = payload.sku_read || payload.sku
    const payloadRfid = payload.rfid
    const variazioneImporto = payload.correzione_importo ?? 0
    const pkConsumer = context.state.consumer?.pk_consumer ?? ''

    /**
     * Filters the `currentSale.capi` array to find any existing items with the same RFID as the provided `payloadRfid`.
     *
     * @param {ApiV1SalesDataAttributesCapi[]} context.state.currentSale.capi - The array of capi items in the current sale.
     * @param {string} payloadRfid - The RFID value to match against the capi items.
     * @returns {ApiV1SalesDataAttributesCapi[]} An array of capi items that have the same RFID as the provided `payloadRfid`.
     */
    const capiOldRfid =
      context.state.currentSale.capi?.filter(
        (capo) =>
          capo.rfid &&
          payloadRfid &&
          capo.rfid === payloadRfid &&
          payloadRfid !== '',
      ) ?? []

    /**
     * Checks if there are any existing capi items in the current sale
     * with the same RFID as the provided `payloadRfid`.
     * If there are, it opens an error modal to alert the user.
     *
     * @param {ApiV1SalesDataAttributesCapi[]} capiOldRfid - An array of capi items that have the same RFID as the provided `payloadRfid`.
     * @param {string} payloadRfid - The RFID value to match against the capi items.
     * @param {string} sku - The SKU value to include in the error message.
     * @param {ActionContext<SalesState, RootState>} context - The Vuex action context.
     * @returns {boolean} `false` if there are existing capi items with the same RFID, `true` otherwise.
     */
    if (capiOldRfid.length > 0) {
      context.commit(Mutations.STORE_SKU_ALERT_OPEN, true)
      modalController.open({
        type: ModalTypes.ERROR,
        component: '',
        title: i18n.global.t('pos_common.error'),
        message: i18n.global.t('pos_sale.item_added_more_times_rfid', {
          payloadRfid,
          sku,
        }),
        customClass:
          'fe-custom-modal-rfid-item-more-time exclude-click-outside',
        confirmLabel: '',
        confirmActionButton: false,
        closedAction: () =>
          context.commit(Mutations.STORE_SKU_ALERT_OPEN, false),
      })
      return false
    }

    try {
      /**
       * Fetches model details from the catalog API for the specified SKU, RFID (if enabled), and consumer PK.
       *
       * @param {string} sku - The SKU of the product to fetch details for.
       * @param {string} [payloadRfid] - The RFID value to include in the API request, if the SKU_GRUPPO_ENABLED config is truthy.
       * @param {string} [pkConsumer] - The PK of the consumer to include in the API request.
       * @returns {Promise<ApiV1PoswebModelsDataGetResponse>} The response from the catalog API containing the model details.
       */
      const modelDetails = await catalogApi.apiV1PoswebModelsDataGet(
        sku,
        isConfigTruthy('SKU_GRUPPO_ENABLED') ? payloadRfid : undefined,
        undefined,
        1,
        pkConsumer,
      )
      if (modelDetails.data.data && modelDetails.data.data[0].attributes) {
        /**
         * Creates an object that combines the attributes from the `modelDetails.data.data[0].attributes` object with the `rfid` property.
         *
         * @param {GetSkuDataAttributes} modelDetails.data.data[0].attributes - The attributes object from the API response.
         * @param {string} payloadRfid - The RFID value to include in the resulting object.
         * @returns {GetSkuDataAttributes} An object with the combined attributes and RFID.
         */
        const productAttributes: GetSkuDataAttributes = {
          ...modelDetails.data.data[0].attributes,
          rfid: payloadRfid, // received from RFID-API, else undefined
        }

        /**
         * Checks if there are any free alterations with textile-only items in the cart.
         * If there are no products with a sale movement type, but there are extra tailoring items,
         * it opens a modal to alert the user.
         */
        if (productAttributes.tipo_mov === TipiVendita.VENDITA_TESSUTO) {
          const extraTailorings =
            store.getters[SalesGetters.GET_EXTRA_TAILORING]
          const products = store.getters[SalesGetters.GET_CAPI]?.filter(
            (prod) => prod.codice_movimento === TipiVendita.VENDITA,
          )

          if (products.length === 0 && extraTailorings.length !== 0) {
            openModalTailoringAlert({
              closedAction: async () => {
                await store.dispatch(SalesActions.UPDATE_TAILORING, [])
                await store.dispatch(SalesActions.CONFIRM_TAILORING_ALTERATIONS)
              },
              confirmButton: {
                label: i18n.global.t('pos_common.proceed'),
                icons: 'arrow-right',
              },
            })
          }
        }

        // it has to be UTC time in this specific format
        const creationDate = format(
          addMinutes(new Date(), new Date().getTimezoneOffset()),
          ISO8601DateTimeStandard,
        )
        const preCustomData = JSON.parse(payload.custom_data || '{}')
        const tailoringDeliveryDate = preCustomData.data_consegna_sartoria || ''
        const lailoringNotes = preCustomData.nota_lavorazioni || ''
        const reasonForDiscount = preCustomData.causale_sconto || ''

        if (context.state.currentSale.capi) {
          let customData = Object.assign(
            {},
            {
              desc_taglia: productAttributes.desc_taglia,
              desc_classe: productAttributes.desc_classe,
              variante: productAttributes.variante,
              annostag: productAttributes.annostag,
              modello: productAttributes.modello,
              data_consegna_sartoria: tailoringDeliveryDate,
              nota_lavorazioni: lailoringNotes,
              sconto_perc_attuale: productAttributes.tipo_importo
                ? (
                    productAttributes.listini as Record<
                      string,
                      GetSkuDataAttributesListiniPriceType
                    >
                  )[productAttributes?.tipo_importo].perc_attuale
                : 0,
              causale_sconto: reasonForDiscount || '',
            },
          )
          if (payload.custom_data) {
            customData = Object.assign(
              {},
              customData,
              JSON.parse(payload.custom_data),
            )
          }

          const orderCapi = context.state.currentSale.capi?.sort(
            (a, b) => (a?.progressivo ?? 0) - (b?.progressivo ?? 0),
          )
          /**
           * Calculates the next progressive number for a new item in the current sale.
           * If there are existing items in the current sale, the next progressive number
           * is calculated by taking the last item's progressive number and incrementing it by 1.
           * If there are no existing items, the progressive number is set to 1.
           */
          const progressivo_capi =
            payload?.progressivo ??
            (orderCapi.length > 0
              ? (orderCapi[orderCapi?.length - 1]?.progressivo ?? 0) + 1
              : 1)

          const newCapo = {
            // Make sure to copy (and/or overwrite) default data with what we have received from the hydration process.
            ...(payload || {}),
            classe: productAttributes?.classe,
            cod_negozio: context.rootState.configs.currentStore?.STORE_CODE,
            codice_movimento:
              payload.codice_movimento &&
              payload.codice_movimento === TipiVendita.LAYBY
                ? payload.codice_movimento
                : productAttributes.tipo_mov || TipiMovimenti.VENDITA,
            composizione: productAttributes.composizione,
            correzione_importo: variazioneImporto,
            custom_data: JSON.stringify(customData),
            ean: productAttributes.ean,
            flag_divisa:
              payload.flag_divisa &&
              payload.codice_movimento === TipiVendita.LAYBY
                ? payload.flag_divisa
                : productAttributes.flag_divisa,
            flag_promo:
              payload.flag_promo &&
              payload.codice_movimento === TipiVendita.LAYBY
                ? payload.flag_promo
                : productAttributes.flag_promo,
            importo_custom: payload.importo_custom ? payload.importo_custom : 0,
            importo_iniziale:
              payload.importo_iniziale &&
              payload.codice_movimento === TipiVendita.LAYBY
                ? payload.importo_iniziale
                : (productAttributes.prezzo_iniziale ??
                  productAttributes.importo_finale),
            importo_finale:
              payload.importo_finale &&
              payload.codice_movimento === TipiVendita.LAYBY
                ? payload.importo_finale
                : (productAttributes.importo_finale ?? 0) + variazioneImporto,
            tipo_importo: productAttributes.tipo_importo,
            iva: productAttributes.iva,
            nome: productAttributes.nome,
            nota: '',
            progressivo: progressivo_capi,
            rfid: productAttributes.rfid,
            sconto: 0,
            sku_created: 0,
            sku_read: sku,
            sku_splitted: 0,
            sku_gruppo: productAttributes.sku_gruppo,
            tipologia_merce: productAttributes.tipologia_merce,
            codice_stato: context.state.currentSale.codice_stato,
            cod_commessa:
              payload.cod_commessa || context.state.selected_rdv?.venditrice,
            data_creazione: creationDate,
            sku: productAttributes.sku,
            error_code: productAttributes.error_code,
          }

          addToCartEvent([
            {
              model: productAttributes.nome || '',
              sku: sku || '',
              color: '', // TODO: add color
              size: '', // TODO: add size
              model_code: '', // TODO: add model_code
              price: {
                currentPrice: newCapo.importo_finale?.toString() || '',
                fullPrice: newCapo.importo_iniziale?.toString() || '',
                currency: store.state.configs.currentStore?.CURRENCY_SIGN,
              },
            },
          ])

          if (context.state.currentSale.id_transazione) {
            // FIXME: this should not be required: `id_transazione` should be a number (but it is actually a string)
            const id = parseInt(
              `${context.state.currentSale.id_transazione}`,
              10,
            )
            newCapo.id_transazione = id
          }
          // Try to minimize the time available for a race condition to happen. By storing this value
          // as soon as possible, even multiple "concurrent" calls of this action will not result in
          // merged products details. If something bad happens during the execution of this action,
          // we will revert this value to the one previously stored.
          context.commit(
            Mutations.STORE_PROGRESSIVO_CAPO,
            payload.progressivo ?? progressivo_capi,
          )
          context.commit(Mutations.STORE_PRODUCTS, {
            ...context.state.product_detail,
            [progressivo_capi]: {
              ...productAttributes,
              custom_data: customData,
            },
          })
          context.commit(Mutations.STORE_SALE_CAPI, [
            ...context.state.currentSale.capi,
            newCapo,
          ])
          context.dispatch(Actions.CHECK_DUPLICATE_SKU, productAttributes.sku)
          if (context.getters[Getters.GET_COUPON]) {
            context.dispatch(Actions.VALIDATE_COUPON)
          }
          await context.dispatch(Actions.CONFIRM_DISCOUNTS)
          await context.dispatch(Actions.COMPUTE_TOTALS)
          await context.dispatch(Actions.CHECK_PRODUCTS_STOCK)
        }
      }

      return true
    } catch (e: unknown) {
      const error = e as ErrorD
      context.dispatch(
        NotificationsActions.NOTIFY_ERROR,
        error?.response?.data?.errors?.length
          ? error.response.data.errors[0]?.detail
          : 'pos_sale.barcode_scan_not_valid',
        { root: true },
      )
      return false
    }
  },
  /**
   * Adds a new capo (a type of product) to the current sale.
   *
   * @param context - The action context, which provides access to the Vuex store state and mutations.
   * @param capo - The capo object to be added to the current sale.
   * @returns A Promise that resolves when the action is completed.
   */
  [Actions.ADD_CAPO]: async (
    context: ActionContext<SalesState, RootState>,
    capo: ApiV1SalesDataAttributesCapi | ApiV1SalesSaleIdDataAttributesCapi,
  ): Promise<void> => {
    const progressivo_capi = context.state.progressivo_capi + 1
    if (context.state.currentSale.capi) {
      context.commit(Mutations.STORE_PROGRESSIVO_CAPO, progressivo_capi)
      context.commit(Mutations.STORE_SALE_CAPI, [
        ...context.state.currentSale.capi,
        capo,
      ])
    }
    await context.dispatch(Actions.COMPUTE_TOTALS)
  },
  /**
   * Removes a capo (product) from the current sale.
   * @param context - The action context, which provides access to the Vuex store state and mutations.
   * @param progressivo - The progressivo (unique identifier) of the capo to be removed.
   * @returns A Promise that resolves when the action is completed.
   */
  [Actions.REMOVE_CAPO]: async (
    context: ActionContext<SalesState, RootState>,
    progressivo: number,
  ): Promise<void> => {
    if (context.state.currentSale.capi) {
      context.commit(
        Mutations.STORE_SALE_CAPI,
        context.state.currentSale.capi.filter(
          (c) => c.progressivo !== progressivo,
        ),
      )
      context.dispatch(Actions.RESET_CAPO_ALTERATIONS, progressivo)
      const tmpProduct_detail = context.state.product_detail
      delete tmpProduct_detail[progressivo]
      context.commit(Mutations.STORE_PRODUCTS, tmpProduct_detail)
    }
    if (context.getters[Getters.GET_COUPON]) {
      await context.dispatch(Actions.VALIDATE_COUPON)
    }

    await context.dispatch(Actions.CONFIRM_DISCOUNTS)
    await context.dispatch(Actions.COMPUTE_TOTALS)
    await context.dispatch(Actions.CHECK_PRODUCTS_STOCK)
  },
  /**
   * Updates a capo (product) in the current sale.
   * @param context - The action context, which provides access to the Vuex store state and mutations.
   * @param capo - The updated capo object to be stored in the current sale.
   * @returns Void, as this is a mutation that directly modifies the store state.
   */
  [Actions.UPDATE_CAPO]: (
    context: ActionContext<SalesState, RootState>,
    capo: ApiV1SalesDataAttributesCapi | ApiV1SalesSaleIdDataAttributesCapi,
  ): void => {
    if (context.state.currentSale.capi) {
      if (context.state.currentSale.capi) {
        context.commit(
          Mutations.STORE_SALE_CAPI,
          context.state.currentSale.capi.map((c) =>
            c.progressivo === capo.progressivo ? capo : c,
          ),
        )
      }
    }
    context.dispatch(Actions.COMPUTE_TOTALS)
  },
  [Actions.SET_CONSUMER]: async (
    { commit, state, dispatch }: ActionContext<SalesState, RootState>,
    payload: StoreConsumerPayload,
  ): Promise<void> => {
    try {
      await store.dispatch(SalesActions.UPDATE_FULL_PAYMENT_DATA, {})
      await store.dispatch(SalesActions.UPDATE_FULL_PAYMENT_DATA, {
        paymentForm: {},
        paymentKey: PaymentKeys.CHEQUE,
      })
      commit(Mutations.STORE_CONSUMER, payload)
      store.dispatch(AddressValidationActions['reset-address-validation'])
      store.dispatch(
        AddressValidationActions['reset-address-validation-shipping'],
      )

      // await dispatch(Actions.RESET_DOCUMENTO_SPEDIZIONE)
      await dispatch(Actions.REMOVE_DATI_DOCUMENTI_KEYS, [
        'shipping_address',
        'shipping_destination',
      ])

      commit(Mutations.STORE_SALE, {
        ...state.currentSale,
        dati_documenti: payload?.consumer?.dati_documenti ?? '{}',
        pk_consumer: payload.consumer?.pk_consumer,
      })

      /* const isAnonymous = store.state.consumers.anonymousConsumer.some(
        (ac) => ac.pk_consumer === payload.consumer?.pk_consumer
      )*/
      // TODO: 2023-10-11 ho spostato il controllo della privacy vedi task https://jira.mmfg.it/browse/FGABS-2329
      const maxPayableAmount =
        store.getters[ConfigGetters.GET_MAX_PAYABLE_CASH_AMOUNT]

      if (
        !state.consumer ||
        !state.consumer.pk_consumer ||
        maxPayableAmount >= 9999999.0
      ) {
        return
      }
      const usedCash =
        await consumerDataApi.apiV1ConsumersPkConsumerUsedCashGet(
          state.consumer.pk_consumer,
        )
      if (!usedCash.data.data?.attributes) {
        return
      }
      const amount = usedCash.data.data.attributes.amount ?? 0
      commit(Actions.SET_USED_CASH, usedCash.data.data?.attributes)

      // SEND CHANGE CUSTOMER EVENT
      changeCustomerEvent({
        name: payload.consumer?.nome || '',
        surname: payload.consumer?.cognome || '',
        code: payload.consumer?.pk_consumer || '',
      })

      // IF CART IS NOT EMPTY, SEND ADD TO CART EVENT WITH NEW CUSTOMER INFO
      if (state.currentSale.capi?.length) {
        const products: Product[] = state.currentSale.capi.map(
          (item: ApiV1SalesSaleIdDataAttributesCapi): Product => {
            return {
              sku: item.sku || '',
              model: item.nome || '',
              price: {
                currentPrice: item.importo_finale?.toString() || '',
                fullPrice: item.importo_iniziale?.toString() || '',
                discount: item.sconto,
                currency: store.state.configs.currentStore?.CURRENCY_SIGN,
              },
            }
          },
        )
        addToCartEvent(products)
      }
      if (amount > 0) {
        const consumerFromUeMethod = ((consumer: GetConsumerAttributes) => {
          const consumerNazioneIso = consumer.nazione_iso
          const selectedNation = store.getters[GenericGetters.GET_NATIONS].find(
            (nation: GetCountriesAttributes) =>
              nation.nazione_iso === consumerNazioneIso,
          )

          return selectedNation
        })(state.consumer)
        const getCountryStore = store.getters[ConfigGetters.GET_COUNTRY_CODE]
        let message = ''
        const consumerCSameStore =
          consumerFromUeMethod?.nazione_iso === getCountryStore

        const soglia_extra_ue_aml =
          store.getters[ConfigGetters.GET_MAX_PAYABLE_CASH_AMOUNT_EXTRA_AML]
        const sogliaExtraStoreC =
          store.getters[ConfigGetters.GET_MAX_PAYABLE_CASH_AMOUNT_EXTRA_COUNTRY]
        const sogliaStoreC =
          store.getters[ConfigGetters.GET_MAX_PAYABLE_CASH_AMOUNT]
        const remaing_amount_below_threshold = formatNumero(
          sogliaExtraStoreC - amount,
        )
        const remaing_amount_over_threshold = formatNumero(
          soglia_extra_ue_aml - amount,
        )
        const remaing_amount_below_threshold_store = formatNumero(
          sogliaStoreC - amount,
        )
        const storeSign = store.state.configs.currentStore?.CURRENCY_SIGN
        const currencySign = store.state.configs.currentStore?.CURRENCY_SIGN
        if (consumerCSameStore) {
          if (amount < sogliaStoreC) {
            message = `${i18n.global.t(
              'pos_sale.max_money_amount_rules',
            )}:<br/>${i18n.global.t(
              'pos_sale.max_money_amount_accepted_for_consumer',
              {
                c_sign: storeSign,
                cur_sign: currencySign,
                rim: remaing_amount_below_threshold_store,
                soglia: formatNumero(sogliaStoreC),
              },
            )}`
          }
        } else {
          if (amount < sogliaExtraStoreC) {
            message = `${i18n.global.t(
              'pos_sale.max_money_amount_rules',
            )}:<br/>${i18n.global.t(
              'pos_sale.min_money_amount_foreign_consumer_with_data',
              {
                c_sign: storeSign,
                cur_sign: currencySign,
                rim: remaing_amount_below_threshold,
                soglia: formatNumero(sogliaExtraStoreC),
                soglia_stra: formatNumero(soglia_extra_ue_aml),
              },
            )}`
          } else {
            message = `${i18n.global.t(
              'pos_sale.max_money_amount_rules',
            )}:<br/>${i18n.global.t(
              'pos_sale.max_money_amount_accepted_for_foreign_consumer',
              {
                c_sign: storeSign,
                cur_sign: currencySign,
                rim: remaing_amount_over_threshold,
                soglia_stra: formatNumero(soglia_extra_ue_aml),
              },
            )}`
          }
        }

        if (!payload.noAlert) {
          // XXX: modal
          modalController.open({
            name: 'max-money-amount__modal',
            type: ModalTypes.INFO,
            component: '',
            title: i18n.global.t('pos_common.attention'),
            message: message,
            confirmLabel: i18n.global.t('pos_common.continue'),
            confirmActionButton: true,
          })
        }
      }
    } catch (err: unknown) {
      const error = err as ErrorD
      await store.dispatch(
        NotificationsActions.NOTIFY_ERROR,
        (error?.response?.data?.errors || [])[0].detail,
      )
    }
  },
  [Actions.RESET_CONSUMER]: (context: ActionContext<SalesState, RootState>) => {
    context.commit(Mutations.CLEAR_CONSUMER)
    context.commit(Mutations.CLEAR_USED_CASH)
    store.dispatch(AddressValidationActions['reset-address-validation'])
    store.dispatch(
      AddressValidationActions['reset-address-validation-shipping'],
    )
    // context.dispatch(Actions.RESET_DOCUMENTO_SPEDIZIONE)
    context.dispatch(Actions.REMOVE_DATI_DOCUMENTI_KEYS, [
      'shipping_address',
      'shipping_destination',
    ])
    context.commit(Mutations.STORE_SALE, {
      ...context.state.currentSale,
      pk_consumer: '',
    })
  },
  [Actions.SET_SALE_DATI_PROMO]: (
    context: ActionContext<SalesState, RootState>,
    // TODO: change type
    payload: any,
  ) => {
    context.commit(Mutations.UPDATE_SALE_DATA, payload)
  },
  [Actions.SET_CASHIER]: (
    context: ActionContext<SalesState, RootState>,
    cashier: GetCashiersAttributes,
  ): void => {
    context.commit(Mutations.STORE_CASHIER, cashier)
    context.commit(Mutations.ADD_COD_COMMESSA, cashier.venditrice || '')
    context.commit(Mutations.STORE_SALE, {
      ...context.state.currentSale,
      cod_cassiera: cashier.venditrice,
    })
    if (!context.state.selected_rdv) {
      context.commit(Mutations.STORE_RDV, cashier)
    }
  },
  [Actions.SET_MONEY_CHANGE]: (
    context: ActionContext<SalesState, RootState>,
    resto: number,
  ): void => {
    context.commit(Mutations.STORE_SALE, {
      ...context.state.currentSale,
      resto: resto,
    })
  },
  [Actions.RESET_CASHIER]: (
    context: ActionContext<SalesState, RootState>,
  ): void => {
    context.commit(Mutations.CLEAR_CASHIER)
    context.commit(Mutations.STORE_SALE, {
      ...context.state.currentSale,
      cod_cassiera: undefined,
    })
  },
  [Actions.SET_RDV]: (
    context: ActionContext<SalesState, RootState>,
    cashier: GetCashiersAttributes,
  ): void => {
    context.commit(Mutations.STORE_RDV, cashier)
  },
  [Actions.RESET_RDV]: (
    context: ActionContext<SalesState, RootState>,
  ): void => {
    context.commit(Mutations.CLEAR_RDV)
  },
  [Actions.SET_ANONYMOUS_CONSUMER]: (
    context: ActionContext<SalesState, RootState>,
    anonymousConsumer: string,
  ): void => {
    context.commit(Mutations.STORE_SALE, {
      ...context.state.currentSale,
      pk_consumer: anonymousConsumer,
    })
    changeCustomerEvent({
      name: '',
      surname: '',
      code: anonymousConsumer,
    })
    context.commit(Mutations.CLEAR_CONSUMER)
  },
  [Actions.RESET_ANONYMOUS_CONSUMER]: (
    context: ActionContext<SalesState, RootState>,
  ): void => {
    context.commit(Mutations.STORE_SALE, {
      ...context.state.currentSale,
      pk_consumer: undefined,
    })
    context.commit(Mutations.CLEAR_CONSUMER)
  },
  [Actions.SET_NOTA]: (
    context: ActionContext<SalesState, RootState>,
    nota: string,
  ): void => {
    context.commit(Mutations.STORE_SALE, {
      ...context.state.currentSale,
      nota,
    } as ApiV1SalesDataAttributes | ApiV1SalesSaleIdDataAttributes)
  },
  [Actions.RESET_NOTA]: (
    context: ActionContext<SalesState, RootState>,
  ): void => {
    context.commit(Mutations.STORE_SALE, {
      ...context.state.currentSale,
      nota: '',
    } as ApiV1SalesDataAttributes | ApiV1SalesSaleIdDataAttributes)
  },
  [Actions.SET_ANONYMOUS]: (
    context: ActionContext<SalesState, RootState>,
    isAnonymous: boolean,
  ): void => {
    context.commit(Mutations.CLEAR_CONSUMER)
    context.commit(Mutations.STORE_ANONYMOUS_FLAG, isAnonymous)
    if (isAnonymous) {
      context.dispatch(
        Actions.SET_ANONYMOUS_CONSUMER,
        AnonymousConsumers.SUPER_ANONYMOUS,
      )
    }
  },
  [Actions.SET_PAYMENTS]: (
    context: ActionContext<SalesState, RootState>,
    payments:
      | ApiV1SalesDataAttributesPagamenti[]
      | ApiV1SalesSaleIdDataAttributesPagamenti[],
  ): void => {
    if (context.state.currentSale.pagamenti) {
      context.commit(Mutations.STORE_SALE_PAGAMENTI, payments)
      context.commit(
        Mutations.STORE_PRODUCTS,
        context.state.currentSale.pagamenti
          .map((pagamento) => pagamento.progressivo ?? 0)
          .reduce((v: { [key: string]: boolean }, p: number) => {
            v[p] = true
            return v
          }, {}),
      )
    }
  },
  [Actions.RESET_PAYMENTS]: (
    context: ActionContext<SalesState, RootState>,
  ): void => {
    context.commit(Mutations.CLEAR_SALE_PAGAMENTI)
    store.commit(PaymentsMutations.CLEAR_VALIDATION_PAGAMENTI)
  },
  [Actions.RESET_ONLY_PAYMENTS_TYPE]: (
    context: ActionContext<SalesState, RootState>,
  ): void => {
    context.commit(Mutations.CLEAR_ONLY_PAYMENTS_TYPE)
    store.commit(PaymentsMutations.CLEAR_VALIDATION_PAGAMENTI)
  },
  [Actions.ADD_PAYMENT]: (
    context: ActionContext<SalesState, RootState>,
    pagamento:
      | ApiV1SalesDataAttributesPagamenti
      | ApiV1SalesSaleIdDataAttributesPagamenti,
  ): void => {
    const progressivo_pagamenti = context.state.progressivo_pagamenti + 1
    pagamento.progressivo = progressivo_pagamenti
    const isTailoring =
      pagamento.codice_movimento === TipiMovimenti.CONTABILITA_SARTORIA ||
      pagamento.codice_movimento === TipiMovimenti.CONTABILITA_SARTORIA_OMAGGIO

    const isGiftCard = pagamento.cod_operazione === 'GIFT_CARD'
    let isCash: boolean | null = pagamento.cod_operazione === 'CONTANTI'
    let progressivo = pagamento.progressivo
    if (
      pagamento.codice_movimento === TipiMovimenti.CONTABILITA_SCONTO ||
      pagamento.codice_movimento === TipiMovimenti.CONTABILITA_ABBUONO ||
      pagamento.codice_movimento === TipiMovimenti.CONTABILITA_COUPON ||
      pagamento.codice_movimento === TipiMovimenti.CONTABILITA_SHOPPING_BAG ||
      pagamento.codice_movimento === TipiMovimenti.CONTABILITA_SPEDIZIONE ||
      (pagamento.progressivo_capo === 0 && isTailoring)
    ) {
      isCash = null
    } else if (pagamento.progressivo_capo !== 0 && isTailoring) {
      isCash = true
    }

    if (context.state.currentSale.id_transazione) {
      pagamento.id_transazione = context.state.currentSale.id_transazione
    }

    if (context.state.currentSale.pagamenti) {
      const extraTailoringAlreadyExisting =
        context.state.currentSale.pagamenti.filter((currentPayment) => {
          if (
            (currentPayment.codice_movimento !==
              TipiMovimenti.CONTABILITA_SARTORIA &&
              pagamento.codice_movimento !==
                TipiMovimenti.CONTABILITA_SARTORIA_OMAGGIO) ||
            !currentPayment.dati_operazione ||
            !pagamento.dati_operazione
          ) {
            return false
          }

          if (
            JSON.parse(currentPayment.dati_operazione).classe ===
              JSON.parse(pagamento.dati_operazione).classe &&
            currentPayment.cod_operazione === pagamento.cod_operazione
          ) {
            return true
          }
        })

      if (
        extraTailoringAlreadyExisting.length === 0 ||
        pagamento.cod_operazione
      ) {
        context.commit(
          Mutations.STORE_PROGRESSIVO_PAGAMENTI,
          progressivo_pagamenti,
        )
        context.commit(Mutations.STORE_SALE_PAGAMENTI, [
          ...context.state.currentSale.pagamenti,
          pagamento,
        ])
      } else {
        isCash = true
        progressivo =
          extraTailoringAlreadyExisting[0].progressivo ?? pagamento.progressivo
      }
      store.commit(PaymentsMutations.STORE_VALIDATION_PAGAMENTI, {
        ...store.state.payments.payments_validator,
        [progressivo]: isTailoring || isGiftCard ? true : isCash,
      })
    }
  },
  [Actions.EDIT_PAYMENT]: (
    context: ActionContext<SalesState, RootState>,
    pagamento:
      | ApiV1SalesDataAttributesPagamenti
      | ApiV1SalesSaleIdDataAttributesPagamenti,
  ): void => {
    if (context.state.currentSale.pagamenti) {
      context.commit(
        Mutations.STORE_SALE_PAGAMENTI,
        context.state.currentSale.pagamenti.map((p) => {
          return p.progressivo === pagamento.progressivo ? pagamento : p
        }),
      )
    }
  },
  [Actions.REMOVE_PAYMENT]: (
    context: ActionContext<SalesState, RootState>,
    progressivo: number,
  ): void => {
    if (context.state.currentSale.pagamenti) {
      context.commit(
        Mutations.STORE_SALE_PAGAMENTI,
        context.state.currentSale.pagamenti.filter((p) => {
          return p.progressivo !== progressivo
        }),
      )
      const tmpPayments_validator = store.state.payments.payments_validator
      delete tmpPayments_validator[progressivo]
      store.commit(
        PaymentsMutations.STORE_VALIDATION_PAGAMENTI,
        tmpPayments_validator,
      )
    }
  },
  [Actions.ADD_PAYMENT_DATA]: (
    context: ActionContext<SalesState, RootState>,
    payload: {
      progressivo: number
      formData: string
      importo: number
      id_transazione: number
      importo_da_pagare: number
      giftCardBarcode: string | undefined
    },
  ): void => {
    if (context.state.currentSale.pagamenti) {
      const payments_list = context.state.currentSale.pagamenti?.map(
        (pagamento) => {
          let datiOperazione = ''
          if (pagamento.progressivo === payload.progressivo) {
            if (payload.giftCardBarcode) {
              datiOperazione =
                payload.formData === ''
                  ? '{}'
                  : JSON.stringify({
                      dati_gift_card: {
                        barcode: payload.giftCardBarcode, // Aggiungi il barcode, dal payload
                        ...JSON.parse(payload.formData).dati_gift_card, // Parsa il formData attuale
                      },
                    })
            } else {
              datiOperazione = payload.formData === '' ? '{}' : payload.formData
            }
            return {
              ...pagamento,
              dati_operazione: datiOperazione,
              importo_finale: payload.importo_da_pagare ?? payload.importo,
              importo_iniziale: payload.importo,
              id_transazione: payload.id_transazione,
            }
          }
          return pagamento
        },
      )
      context.commit(Mutations.STORE_SALE_PAGAMENTI, payments_list)
      store.commit(PaymentsMutations.STORE_VALIDATION_PAGAMENTI, {
        ...store.state.payments.payments_validator,
        [payload.progressivo]: true,
      })
    }
  },
  /**
   * Action made to store credit card amount without setting its validity to true
   * @param context
   * @param payload
   */
  [Actions.STORE_PAYMENT_AMOUNT]: (
    context: ActionContext<SalesState, RootState>,
    payload: { importo: number; progressivo: number },
  ): void => {
    context.commit(
      Mutations.STORE_SALE_PAGAMENTI,
      context.state.currentSale.pagamenti?.map((p) =>
        p.progressivo === payload.progressivo
          ? {
              ...p,
              dati_operazione: p.dati_operazione ? p.dati_operazione : '{}',
              importo_finale: payload.importo,
              importo_iniziale: payload.importo,
            }
          : p,
      ),
    )
  },
  [Actions.RESET_DISCOUNTS]: (
    context: ActionContext<SalesState, RootState>,
  ): void => {
    const tmpPayments_validator = {
      ...store.state.payments.payments_validator,
    }
    if (context.state.currentSale.pagamenti) {
      const tmpPagamenti = context.state.currentSale.pagamenti.filter(
        (p) =>
          p.codice_movimento !== TipiMovimenti.CONTABILITA_SCONTO ||
          (p.codice_movimento === TipiMovimenti.CONTABILITA_SCONTO &&
            tmpPayments_validator[String(p.progressivo)] !== null),
      )

      context.state.currentSale.pagamenti.forEach((p) => {
        if (p.codice_movimento === TipiMovimenti.CONTABILITA_SCONTO) {
          if (tmpPayments_validator[String(p.progressivo)] === null) {
            delete tmpPayments_validator[String(p.progressivo)]
          } else {
            tmpPayments_validator[String(p.progressivo)] = false
          }
        }
      })

      context.commit(Mutations.STORE_SALE_PAGAMENTI, tmpPagamenti)
      store.commit(
        PaymentsMutations.STORE_VALIDATION_PAGAMENTI,
        tmpPayments_validator,
      )
    }
  },
  /**
   * Confirms the discounts applied to the current sale.
   * This action updates the `payments_validator` object in the `payments` store module based on the discounts in the `currentSale.pagamenti` array.
   * It also calculates the total discount amount for each article in the sale and updates the `importo_iniziale` and `importo_finale` properties of the corresponding payment.
   * @param {ActionContext<SalesState, RootState>} context - The action context, containing the current state and the root state.
   * @param {boolean} deletion - A flag indicating whether the discounts are being deleted or confirmed.
   * @returns {void}
   */
  [Actions.CONFIRM_DISCOUNTS]: (
    context: ActionContext<SalesState, RootState>,
    deletion: false,
  ): void => {
    if (context.state.currentSale.pagamenti) {
      const tmpPayments_validator = {
        ...store.state.payments.payments_validator,
      }

      const tmpPagamenti = context.state.currentSale.pagamenti.filter((p) => {
        if (p.codice_movimento === TipiMovimenti.CONTABILITA_SCONTO) {
          if (
            (tmpPayments_validator[String(p.progressivo)] === null &&
              deletion) ||
            (tmpPayments_validator[String(p.progressivo)] === false &&
              !deletion)
          ) {
            delete tmpPayments_validator[String(p.progressivo)]
            return false
          }

          tmpPayments_validator[String(p.progressivo)] = true
        }
        return true
      })

      // UPDATE discounts calculating it for each article

      const articles = context.state.currentSale?.capi

      tmpPagamenti.forEach((payment) => {
        let discountsTotal = 0
        if (payment.codice_movimento === TipiMovimenti.CONTABILITA_SCONTO) {
          articles.forEach((article) => {
            if (article.codice_movimento !== TipiVendita.SCARICO_OMAGGIO) {
              const pricetoCheck = article.importo_finale
              discountsTotal +=
                (pricetoCheck *
                  Math.abs(
                    parseFloat(JSON.parse(payment.dati_operazione).percentuale),
                  )) /
                100
            }
          })
          const discountsExtraTotalTailoring =
            (context.getters[Getters.GET_EXTRA_TAILORING_PRICE] *
              Math.abs(
                parseFloat(JSON.parse(payment.dati_operazione).percentuale),
              )) /
            100
          const discountsTotalTailoring =
            (context.getters[Getters.GET_TAILORING_NO_EXTRA] *
              Math.abs(
                parseFloat(JSON.parse(payment.dati_operazione).percentuale),
              )) /
            100
          const total_discount = roundDecimals(
            -Math.abs(
              discountsTotal +
                discountsExtraTotalTailoring +
                discountsTotalTailoring,
            ),
          )
          payment.importo_iniziale = total_discount
          payment.importo_finale = total_discount
        }
      })
      context.commit(Mutations.STORE_SALE_PAGAMENTI, tmpPagamenti)
      store.commit(
        PaymentsMutations.STORE_VALIDATION_PAGAMENTI,
        tmpPayments_validator,
      )
    }
  },
  [Actions.REMOVE_DISCOUNTS_VALIDATION]: (
    context: ActionContext<SalesState, RootState>,
  ): void => {
    if (context.state.currentSale.pagamenti) {
      const tmpPayments_validator = {
        ...store.state.payments.payments_validator,
      }
      context.state.currentSale.pagamenti.forEach((p) => {
        if (p.codice_movimento === TipiMovimenti.CONTABILITA_SCONTO) {
          tmpPayments_validator[String(p.progressivo)] = false
        }
      })
      store.commit(
        PaymentsMutations.STORE_VALIDATION_PAGAMENTI,
        tmpPayments_validator,
      )
    }
  },
  [Actions.REMOVE_REBATES_VALIDATION]: (
    context: ActionContext<SalesState, RootState>,
  ): void => {
    if (context.state.currentSale.pagamenti) {
      const tmpPayments_validator = {
        ...store.state.payments.payments_validator,
      }
      context.state.currentSale.pagamenti.forEach((p) => {
        if (p.codice_movimento === TipiMovimenti.CONTABILITA_ABBUONO) {
          tmpPayments_validator[String(p.progressivo)] = false
        }
      })
      store.commit(
        PaymentsMutations.STORE_VALIDATION_PAGAMENTI,
        tmpPayments_validator,
      )
    }
  },
  [Actions.RESET_REBATES]: (
    context: ActionContext<SalesState, RootState>,
  ): void => {
    const tmpPayments_validator = {
      ...store.state.payments.payments_validator,
    }
    if (context.state.currentSale.pagamenti) {
      const tmpPagamenti = context.state.currentSale.pagamenti.filter(
        (p) =>
          p.codice_movimento !== TipiMovimenti.CONTABILITA_ABBUONO ||
          (p.codice_movimento === TipiMovimenti.CONTABILITA_ABBUONO &&
            tmpPayments_validator[String(p.progressivo)] !== null),
      )

      context.state.currentSale.pagamenti.forEach((p) => {
        if (p.codice_movimento === TipiMovimenti.CONTABILITA_ABBUONO) {
          if (tmpPayments_validator[String(p.progressivo)] === null) {
            delete tmpPayments_validator[String(p.progressivo)]
          } else {
            tmpPayments_validator[String(p.progressivo)] = false
          }
        }
      })

      context.commit(Mutations.STORE_SALE_PAGAMENTI, tmpPagamenti)
      store.commit(
        PaymentsMutations.STORE_VALIDATION_PAGAMENTI,
        tmpPayments_validator,
      )
    }
  },
  [Actions.CONFIRM_REBATES]: (
    context: ActionContext<SalesState, RootState>,
    deletion = false,
  ): void => {
    if (context.state.currentSale.pagamenti) {
      const tmpPayments_validator = {
        ...store.state.payments.payments_validator,
      }

      const tmpPagamenti = context.state.currentSale.pagamenti.filter((p) => {
        if (p.codice_movimento === TipiMovimenti.CONTABILITA_ABBUONO) {
          if (
            (tmpPayments_validator[String(p.progressivo)] === null &&
              deletion) ||
            (tmpPayments_validator[String(p.progressivo)] === false &&
              !deletion)
          ) {
            delete tmpPayments_validator[String(p.progressivo)]
            return false
          }

          tmpPayments_validator[String(p.progressivo)] = true
        }
        return true
      })
      context.commit(Mutations.STORE_SALE_PAGAMENTI, tmpPagamenti)
      store.commit(
        PaymentsMutations.STORE_VALIDATION_PAGAMENTI,
        tmpPayments_validator,
      )
    }
  },
  [Actions.RESET_TAILORING_ALTERATIONS]: (
    context: ActionContext<SalesState, RootState>,
  ): void => {
    if (context.state.currentSale.pagamenti) {
      const salePayments = context.state.currentSale.pagamenti.filter(
        (payment: ApiV1SalesDataAttributesPagamenti) => {
          if (
            payment.progressivo_capo === 0 &&
            (payment.codice_movimento === TipiMovimenti.CONTABILITA_SARTORIA ||
              payment.codice_movimento ===
                TipiMovimenti.CONTABILITA_SARTORIA_OMAGGIO)
          ) {
            return false
          }
          return true
        },
      )
      context.commit(Mutations.STORE_SALE_PAGAMENTI, salePayments)
    }
  },
  [Actions.CONFIRM_TAILORING_ALTERATIONS]: async (
    context: ActionContext<SalesState, RootState>,
    deletion = false,
  ): Promise<void> => {
    if (context.state.currentSale.pagamenti) {
      if (!deletion) {
        await context.dispatch(Actions.SET_TAILORING_VALIDATIONS_FALSE)
      }
      const tmpPayments_validator = {
        ...store.state.payments.payments_validator,
      }
      const tmpPagamenti = context.state.currentSale.pagamenti.filter((p) => {
        if (
          p.progressivo_capo === 0 &&
          (p.codice_movimento === TipiMovimenti.CONTABILITA_SARTORIA ||
            p.codice_movimento === TipiMovimenti.CONTABILITA_SARTORIA_OMAGGIO)
        ) {
          if (
            (tmpPayments_validator[String(p.progressivo)] === null &&
              deletion) ||
            (tmpPayments_validator[String(p.progressivo)] === false &&
              !deletion)
          ) {
            delete tmpPayments_validator[String(p.progressivo)]
            return false
          }

          tmpPayments_validator[String(p.progressivo)] = true
        }
        return true
      })
      context.commit(Mutations.STORE_SALE_PAGAMENTI, tmpPagamenti)
      store.commit(
        PaymentsMutations.STORE_VALIDATION_PAGAMENTI,
        tmpPayments_validator,
      )
    }
  },
  [Actions.DELETE_UNCONFIRMED_EXTRA_SERVICES]: (
    context: ActionContext<SalesState, RootState>,
  ): void => {
    if (context.state.currentSale.pagamenti) {
      const tmpPayments_validator = {
        ...store.state.payments.payments_validator,
      }
      context.state.currentSale.pagamenti.forEach((p) => {
        if (
          p.codice_movimento === TipiMovimenti.CONTABILITA_SARTORIA ||
          p.codice_movimento === TipiMovimenti.CONTABILITA_SARTORIA_OMAGGIO
        ) {
          tmpPayments_validator[String(p.progressivo)] = false
        }
      })
      store.commit(
        PaymentsMutations.STORE_VALIDATION_PAGAMENTI,
        tmpPayments_validator,
      )
    }
  },
  [Actions.RESET_CAPO_ALTERATIONS]: (
    context: ActionContext<SalesState, RootState>,
    progressivo: number,
  ) => {
    if (context.state.currentSale.pagamenti) {
      context.commit(
        Mutations.STORE_SALE_PAGAMENTI,
        context.state.currentSale.pagamenti.filter(
          (p) =>
            (p.codice_movimento !== TipiMovimenti.CONTABILITA_SARTORIA &&
              p.codice_movimento !==
                TipiMovimenti.CONTABILITA_SARTORIA_OMAGGIO &&
              p.codice_movimento !== TipiMovimenti.CONTABILITA_SCONTO &&
              p.codice_movimento !== TipiMovimenti.CONTABILITA_ABBUONO) ||
            p.progressivo_capo !== progressivo,
        ),
      )
    }
  },
  [Actions.SET_DOCUMENTO_DATI]: (
    context: ActionContext<SalesState, RootState>,
    documento: FatturaForm | FatturaUEForm,
  ): void => {
    if (context.state.currentSale.dati_documenti) {
      context.commit(Mutations.STORE_SALE, {
        ...context.state.currentSale,
        dati_documenti: JSON.stringify({
          ...JSON.parse(context.state.currentSale.dati_documenti),
          dati_documento: documento,
        }),
      })
    }
  },
  [Actions.UPDATE_DATI_DOCUMENTI]: (
    context: ActionContext<SalesState, RootState>,
    documentiData: object,
  ): void => {
    if (context.state.currentSale.dati_documenti) {
      context.commit(Mutations.STORE_SALE, {
        ...context.state.currentSale,
        dati_documenti: JSON.stringify({
          ...JSON.parse(context.state.currentSale.dati_documenti),
          ...documentiData,
        }),
      })
    }
  },
  [Actions.REMOVE_DATI_DOCUMENTI_KEYS]: (
    context: ActionContext<SalesState, RootState>,
    keysToRemove: string[],
  ): void => {
    if (
      context.state.currentSale.dati_documenti &&
      typeof context.state.currentSale.dati_documenti === 'string'
    ) {
      const dati_documenti = JSON.parse(
        context.state.currentSale.dati_documenti.toString(),
      )
      keysToRemove.forEach((key) => delete dati_documenti[key])
      context.commit(Mutations.STORE_SALE, {
        ...context.state.currentSale,
        dati_documenti: JSON.stringify(dati_documenti),
      })
    }
  },
  [Actions.RESET_DOCUMENTO_DATI]: (
    context: ActionContext<SalesState, RootState>,
  ): void => {
    if (context.state.currentSale.dati_documenti) {
      const dati_documenti = JSON.parse(
        context.state.currentSale.dati_documenti,
      )
      delete dati_documenti.dati_documento
      context.commit(Mutations.STORE_SALE, {
        ...context.state.currentSale,
        dati_documenti: JSON.stringify(dati_documenti),
      })
    }
  },
  [Actions.SET_DOCUMENTO_CODICE]: (
    context: ActionContext<SalesState, RootState>,
    codDocumento: string,
  ) => {
    context.commit(Mutations.STORE_SALE, {
      ...context.state.currentSale,
      cod_documento: codDocumento,
    })
  },
  [Actions.RESET_DOCUMENTO_CODICE]: (
    context: ActionContext<SalesState, RootState>,
  ) => {
    context.commit(Mutations.STORE_SALE, {
      ...context.state.currentSale,
      cod_documento: undefined,
    })
  },
  [Actions.SET_FINAL_PRICE](
    context: ActionContext<SalesState, RootState>,
    price: number,
  ): void {
    context.commit(Mutations.STORE_SALE, {
      ...context.state.currentSale,
      prezzo_finale: price,
    })
  },
  async [Actions.UPDATE_TAILORING](
    { dispatch, state }: ActionContext<SalesState, RootState>,
    alterationsTabs: AlterationsTab[],
  ) {
    await dispatch(Actions.RESET_TAILORING_ALTERATIONS)
    if (!alterationsTabs) {
      alterationsTabs = []
    }
    let price = 0
    const convertedAlterations = alterationsTabs.reduce(
      (
        outputAlterations: GetAlterationPricesDataAttributes[],
        alterationTab: AlterationsTab,
        currentIndex: number,
      ) => {
        const isReturn =
          alterationTab.cod_movimento === TipiVendita.STORNO ||
          alterationTab.cod_movimento === TipiVendita.RESO
        const currentTabAlterations = alterationTab.alterations
          .filter((alteration: AlterationsTabItem): boolean => {
            if (
              ('customPrice' in alteration &&
                (typeof alteration.customType !== 'string' ||
                  alteration.customType.length < 5)) ||
              (alteration.attributes.progressivo === '99' &&
                'customPrice' in alteration === false)
            ) {
              return false
            }

            return alteration.isSelected
          })
          .map(
            (
              alteration: AlterationsTabItem,
            ): GetAlterationPricesDataAttributes => {
              if (alteration.customPrice) {
                price = isReturn
                  ? Math.abs(alteration.customPrice) * -1
                  : Math.abs(alteration.customPrice)
              } else if (alteration.attributes.importo) {
                price = isReturn
                  ? Math.abs(alteration.attributes.importo) * -1
                  : Math.abs(alteration.attributes.importo)
              } else {
                price = 0
              }

              return {
                classe: alteration.attributes.classe,
                idAlterationTab: alterationTab.alterationGroupId,
                cod_negozio: alteration.attributes.cod_negozio,
                data_modifica: alteration.attributes.data_modifica,
                additionalNotes: alterationTab.additionalNotes,
                calendarValue: alterationTab.calendarValue,
                descrizione:
                  alteration.customType && alteration.customType !== ''
                    ? alteration.customType
                    : alteration.attributes.descrizione,
                importo: price,
                importo_finale: alteration.hasPrice ? price : 0,
                progressivo: alteration.attributes.progressivo,
                gruppo: currentIndex + 1,
                cod_movimento: alterationTab.cod_movimento,
                vat: alterationTab.vat,
              }
            },
          )

        return outputAlterations.concat(currentTabAlterations)
      },
      [] as GetAlterationPricesDataAttributes[],
    )

    convertedAlterations.forEach(
      (alteration: GetAlterationPricesDataAttributes) => {
        dispatch(Actions.ADD_PAYMENT, {
          cod_negozio: alteration.cod_negozio,
          idAlterationTab: alteration.idAlterationTab,
          cod_operazione: alteration.progressivo,
          codice_movimento: ((): TipiMovimenti => {
            if (alteration.importo === 0 || alteration.importo_finale === 0) {
              return TipiMovimenti.CONTABILITA_SARTORIA_OMAGGIO
            }
            return TipiMovimenti.CONTABILITA_SARTORIA
          })(),
          dati_operazione: JSON.stringify({
            tipo: 'VENDITA',
            nota_lavorazioni: alteration.additionalNotes,
            data_consegna_sartoria: alteration.calendarValue,
            iva: alteration.vat
              ? alteration.vat
              : (store.state.generic.vatCodes?.vat_percentage ?? 0),
            descrizione: alteration.descrizione,
            classe: alteration.classe,
            rdv: state.selected_rdv?.venditrice,
            gruppo:
              typeof alteration?.gruppo === 'number'
                ? alteration?.gruppo.toString()
                : alteration?.gruppo,
            cod_movimento: alteration.cod_movimento,
          }),
          divisa: undefined,
          id_postazione: undefined,
          importo_finale: alteration.importo_finale,
          importo_iniziale: alteration.importo,
          progressivo_capo: 0,
          tipo_applicazione: 'POSWEB',
        })
      },
    )
  },
  /**
   * Computes the totals for the current sale, including the initial amount, total items, total alterations, total discounts, and total gift cards.
   * It also determines the type of sale (regular sale, return, or gift) and updates the sale state accordingly.
   * @param context - The action context, containing the current sales state and the root state.
   */
  [Actions.COMPUTE_TOTALS]: (context: ActionContext<SalesState, RootState>) => {
    if (context.state.currentSale.capi && context.state.currentSale.pagamenti) {
      const importoIniziale = context.state.currentSale.capi
        .map((c) => {
          return c.codice_movimento === TipiVendita.STORNO ||
            c.codice_movimento === TipiVendita.RESO
            ? Math.abs(c.importo_finale) * -1
            : (c.importo_finale ?? 0)
        })
        .reduce((t: number, i: number) => t + i, 0)

      const totalCapi = context.state.currentSale.capi
        .map((c) => {
          return c.codice_movimento === TipiVendita.STORNO ||
            c.codice_movimento === TipiVendita.RESO
            ? Math.abs(c.importo_finale) * -1
            : (c.importo_finale ?? 0)
        })
        .reduce((t: number, i: number) => t + i, 0)

      const totalSartorie = context.state.currentSale.pagamenti
        .filter(
          (p) => p.codice_movimento === TipiMovimenti.CONTABILITA_SARTORIA,
        )
        .map((c) => c.importo_finale ?? 0)
        .reduce((t: number, i: number) => t + i, 0)

      const totalDiscount = context.state.currentSale.pagamenti
        .filter(
          (p) =>
            p.codice_movimento === TipiMovimenti.CONTABILITA_ABBUONO ||
            p.codice_movimento === TipiMovimenti.CONTABILITA_SCONTO ||
            p.codice_movimento === TipiMovimenti.CONTABILITA_COUPON ||
            p.codice_movimento === TipiMovimenti.CONTABILITA_PROMOZIONE,
        )
        .map((c) => c.importo_finale ?? 0)
        .reduce((t: number, i: number) => t + i, 0)

      const totalGiftCard = context.state.currentSale.pagamenti
        .filter((p) => p.codice_movimento === TipiMovimenti.CONTABILITA_GIFT)
        .map((c) => c.importo_finale ?? 0)
        .reduce((t, i) => t + i, 0)

      const totalShoppingBags = context.state.currentSale.pagamenti
        .filter(
          (p) => p.codice_movimento === TipiMovimenti.CONTABILITA_SHOPPING_BAG,
        )
        .map((c) => c.importo_finale ?? 0)
        .reduce((t, i) => t + i, 0)

      const totalShippingCost = context.state.currentSale.pagamenti
        .filter(
          (p) => p.codice_movimento === TipiMovimenti.CONTABILITA_SPEDIZIONE,
        )
        .map((c) => c.importo_finale ?? 0)
        .reduce((t, i) => t + i, 0)

      const importo_finale = parseFloat(
        (
          totalCapi +
          totalDiscount +
          totalSartorie +
          totalGiftCard +
          totalShoppingBags +
          totalShippingCost
        ).toFixed(2),
      )
      const hasImportoChanged =
        importo_finale !== context.state.currentSale.importo_finale

      const _totalPagamenti = roundDecimals(
        context.state.currentSale.pagamenti
          .filter(
            (p) => p.codice_movimento === TipiMovimenti.CONTABILITA_PAGAMENTO,
          )
          .map((c) => c.importo_finale ?? 0)
          .reduce((t: number, i: number) => t + i, 0),
      )

      // Vendita omaggio
      if (
        context.state.currentSale.capi?.length > 0 &&
        totalCapi + totalDiscount === 0
      ) {
        context.commit(Mutations.STORE_SALE, {
          ...context.state.currentSale,
          codice_movimento: TipiVendita.SCARICO_OMAGGIO,
        })
      } else {
        const tipoVendita =
          context.state.currentSale.codice_movimento === TipiVendita.STORNO ||
          context.state.currentSale.codice_movimento === TipiVendita.RESO
            ? TipiVendita.STORNO
            : TipiVendita.VENDITA
        context.commit(Mutations.STORE_SALE, {
          ...context.state.currentSale,
          codice_movimento: tipoVendita,
        })
      }

      context.commit(Mutations.STORE_SALE, {
        ...context.state.currentSale,
        importo_iniziale: importoIniziale,
        importo_finale: importo_finale,
        importo_pagato: importo_finale,
      })

      if (!hasImportoChanged) {
        return
      }

      if (context.getters[Getters.GET_ONLY_PAYMENTS]?.length === 1) {
        const refPayment = context.getters[Getters.GET_ONLY_PAYMENTS][0]
        if (
          refPayment.cod_operazione === 'CARTA' &&
          store.state.payments.payments_validator[refPayment.progressivo]
        ) {
          return
        }
        context.dispatch(Actions.EDIT_PAYMENT, {
          ...refPayment,
          importo_iniziale: importo_finale,
          importo_finale: importo_finale,
        })
        context.commit(Mutations.STORE_SALE_IMPORTO_PAGATO, importo_finale)
      } else if (context.getters[Getters.GET_ONLY_PAYMENTS]?.length) {
        context.getters[Getters.GET_ONLY_PAYMENTS]?.forEach(
          (p: ApiV1SalesDataAttributesPagamenti) => {
            if (
              p.cod_operazione !== 'CARTA' ||
              (p.progressivo &&
                !store.state.payments.payments_validator[p.progressivo])
            ) {
              context.dispatch(Actions.EDIT_PAYMENT, {
                ...p,
                importo_iniziale: 0,
                importo_finale: 0,
              })
            }
          },
        )
      }
    }
  },
  /**
   * Checks if there are any duplicate SKUs in the current sale and displays an alert if so.
   * @param context - The action context, containing the current sales state and the root state.
   * @param sku - The SKU to check for duplicates.
   */
  [Actions.CHECK_DUPLICATE_SKU]: (
    context: ActionContext<SalesState, RootState>,
    sku: string,
  ) => {
    if (!sku || context.state.skuAlertOpen) {
      return
    }
    const capiDoppi =
      context.state.currentSale.capi?.filter((c) => c.sku === sku) || []
    if (capiDoppi?.length > 1) {
      context.commit(Mutations.STORE_SKU_ALERT_OPEN, true)
      modalController.open({
        type: ModalTypes.INFO,
        component: '',
        title: i18n.global.t('pos_common.attention'),
        message: i18n.global.t('pos_sale.item_added_more_times'),
        confirmLabel: '',
        confirmActionButton: false,
        closedAction: () =>
          context.commit(Mutations.STORE_SKU_ALERT_OPEN, false),
      })
    }
  },
  [Actions.UPDATE_PRODUCT_DETAIL]: (
    context: ActionContext<SalesState, RootState>,
    capo: ApiV1SalesDataAttributesCapi,
  ) => {
    store.commit(SalesMutations.UPDATE_PRODUCT_DETAIL, capo)
  },
  [Actions.UPDATE_PAYMENT_VALIDATION]: (
    context: ActionContext<SalesState, RootState>,
    payload: { progressivo: string; value: boolean },
  ) => {
    store.commit(PaymentsMutations.STORE_VALIDATION_PAGAMENTI, {
      ...store.state.payments.payments_validator,
      [payload.progressivo]: payload.value,
    })
  },
  [Actions.CHECK_COUPON]: async (
    context: ActionContext<SalesState, RootState>,
    couponCode: string,
  ): Promise<void> => {
    const payload = {
      ...context.state.currentSale,
      pagamenti: context.state.currentSale.pagamenti?.filter(
        (p) =>
          p.progressivo &&
          store.state.payments.payments_validator[p?.progressivo] !== false,
      ),
    }

    try {
      const r = await couponsApi.apiV1CouponsCouponCodeCheckPost(couponCode, {
        data: {
          attributes: payload as ApiV1CouponsCouponCodeCheckDataAttributes,
        },
      })

      if (r.data.data?.status) {
        const coupons = context.state.currentSale.pagamenti?.filter(
          (c) => c.codice_movimento === TipiMovimenti.CONTABILITA_COUPON,
        )
        if (coupons) {
          const promises = coupons.map((coupon) =>
            context.dispatch(Actions.DELETE_COUPON, coupon.progressivo),
          )
          await Promise.all(promises)
        }

        try {
          await context.dispatch(Actions.VALIDATE_COUPON, r.data.data)
          return Promise.resolve()
        } catch (e) {
          return Promise.reject(e)
        }
      } else {
        context.dispatch(
          NotificationsActions.NOTIFY_ERROR,
          'pos_sale.coupon_inactive',
          { root: true },
        )
        // eslint-disable-next-line prefer-promise-reject-errors
        return Promise.reject()
      }
    } catch (e) {
      context.dispatch(
        NotificationsActions.NOTIFY_ERROR,
        typeof e === 'string'
          ? extractErrorMessage(e)
          : i18n.global.t('pos_sale.coupon_code_invalid'),
        { root: true },
      )
      // @TODO fix error hendling
      // eslint-disable-next-line prefer-promise-reject-errors
      return Promise.reject()
    }
  },
  /**
   * Validates a coupon and applies it to the current sale.
   * @param context - The action context, containing the current sales state and root state.
   * @param coupon - Optional coupon data to validate and apply.
   * @returns A promise that resolves when the coupon is successfully validated and applied, or rejects if there is an error.
   */
  [Actions.VALIDATE_COUPON]: (
    context: ActionContext<SalesState, RootState>,
    coupon?: CouponData,
  ): Promise<void> => {
    return new Promise<void>((res, rej) => {
      let payload: PostCoupon1
      let paymentCoupon: ApiV1SalesDataAttributesPagamenti
      if ('layby' in context.state.currentSale) {
        context.dispatch(LaybyActions.RESET_LAYBY_DATA)
      }
      if (coupon) {
        const items: { [k: string]: number } = {}
        if (coupon.items?.length) {
          coupon.items?.forEach((r) => {
            if (r.item_code && r.value) {
              items[r.item_code] = r.value
            }
          })
        }
        const totalDiscount = coupon.items?.length
          ? coupon.items?.map((i) => i.value ?? 0).reduce((p, c) => p + c, 0)
          : 0
        paymentCoupon = {
          codice_movimento: TipiMovimenti.CONTABILITA_COUPON,
          tipo_applicazione: 'POSWEB',
          progressivo_capo: 0,
          barcode: '',
          nota: '',
          dati_operazione: JSON.stringify({
            info: {
              importo: coupon.value,
              tipo: coupon.coupon_type,
              stato: 'valido',
              coupon: coupon.id,
              message: '',
            },
            items,
            code: coupon.id,
          }),
          importo_finale: -totalDiscount,
          codice_stato: context.state.currentSale.codice_stato,
          cod_operazione: '',
          importo_iniziale: -totalDiscount,
          id_postazione: context.state.currentSale.id_postazione_apertura,
          cod_negozio: context.state.currentSale.cod_negozio,
          divisa: context.state.currentSale.divisa,
        }
        payload = {
          data: {
            attributes: {
              ...(context.state
                .currentSale as ApiV1CouponsCouponCodeCheckDataAttributes),
              pagamenti: context.state.currentSale?.pagamenti?.length
                ? [
                    ...context.state.currentSale.pagamenti.filter(
                      (p) =>
                        p.progressivo &&
                        store.state.payments.payments_validator[
                          p?.progressivo
                        ] !== false,
                    ),
                    paymentCoupon,
                  ]
                : [paymentCoupon],
            },
          },
        }
      } else {
        payload = {
          data: {
            attributes: context.state
              .currentSale as ApiV1CouponsCouponCodeCheckDataAttributes,
          },
        }
      }
      couponsApi
        .apiV1CouponsValidateSalePost(payload)
        .then(async (r) => {
          if (r.data.data?.status === 'ERROR') {
            // coupon is not valid - if coupon was inserted, it will be removed
            context.commit(Mutations.SET_GIFT_CARD_SAVE_IN_PROCESS, false)
            context.dispatch(
              NotificationsActions.NOTIFY_ERROR,
              extractErrorMessage(r.data.data.error ?? ''),
              { root: true },
            )
            await context.dispatch(
              Actions.REMOVE_PAYMENT,
              context.state.currentSale.pagamenti?.find(
                (p) => p.codice_movimento === TipiMovimenti.CONTABILITA_COUPON,
              )?.progressivo,
            )
            // @TODO fix error handling
            // eslint-disable-next-line prefer-promise-reject-errors
            rej()
          } else if (coupon && paymentCoupon) {
            // if this was called with a coupon as an argument, it needs to be added to payments
            context.dispatch(Actions.ADD_PAYMENT, paymentCoupon)
            res()
          } else {
            // update coupon value and items discount if new items were added
            const totalDiscount = r.data.data?.items?.length
              ? r.data.data.items
                  .map((i) => i.value ?? 0)
                  .reduce((p, c) => p + c, 0)
              : 0
            const items: { [k: string]: number } = {}
            if (r.data.data?.items?.length) {
              r.data.data?.items?.forEach((r) => {
                if (r.item_code && r.value) {
                  items[r.item_code] = r.value
                }
              })
            }
            context.dispatch(Actions.UPDATE_COUPON_VALUE, {
              amount: -totalDiscount,
              items: items,
            })
            res()
          }
        })
        .catch((e) => {
          context.dispatch(
            NotificationsActions.NOTIFY_ERROR,
            extractErrorMessage(e),
            { root: true },
          )
          // @TODO fix error handling
          // eslint-disable-next-line prefer-promise-reject-errors
          rej()
        })
    })
  },
  [Actions.CONFIRM_COUPON]: (
    context: ActionContext<SalesState, RootState>,
    deletion: boolean,
  ) => {
    if (context.state.currentSale.pagamenti) {
      const tmpPayments_validator = {
        ...store.state.payments.payments_validator,
      }

      const tmpPagamenti = context.state.currentSale.pagamenti.filter((p) => {
        if (p.codice_movimento === TipiMovimenti.CONTABILITA_COUPON) {
          if (
            (tmpPayments_validator[String(p.progressivo)] === null &&
              deletion) ||
            (tmpPayments_validator[String(p.progressivo)] === false &&
              !deletion)
          ) {
            delete tmpPayments_validator[String(p.progressivo)]
            return false
          }
          tmpPayments_validator[String(p.progressivo)] = true
        }
        return true
      })
      context.commit(Mutations.STORE_SALE_PAGAMENTI, tmpPagamenti)
      store.commit(
        PaymentsMutations.STORE_VALIDATION_PAGAMENTI,
        tmpPayments_validator,
      )
    }
  },
  [Actions.DELETE_COUPON]: async (
    context: ActionContext<SalesState, RootState>,
    progressivo: number,
  ) => {
    if (context.state.currentSale.pagamenti) {
      await context.dispatch(Actions.REMOVE_PAYMENT, progressivo)
      const tmpValidator = store.state.payments.payments_validator
      delete tmpValidator[progressivo]
      store.commit(PaymentsMutations.STORE_VALIDATION_PAGAMENTI, tmpValidator)
    }
  },
  [Actions.UPDATE_COUPON_VALUE]: (
    context: ActionContext<SalesState, RootState>,
    payload: { amount: number; items: any },
  ) => {
    const { amount, items } = payload
    if (
      context.state.currentSale.pagamenti &&
      context.getters[Getters.GET_COUPON]
    ) {
      const datiOperazione = JSON.parse(
        context.getters[Getters.GET_COUPON].dati_operazione,
      )
      if (items && Object.keys(items).length > 0) {
        datiOperazione.items = items
      }
      const tmpPagamenti = [
        ...context.state.currentSale.pagamenti.filter(
          (p) =>
            p.progressivo !== context.getters[Getters.GET_COUPON].progressivo,
        ),
        {
          ...context.getters[Getters.GET_COUPON],
          dati_operazione: JSON.stringify(datiOperazione),
          importo_iniziale: amount,
          importo_finale: amount,
        },
      ]
      context.commit(Mutations.STORE_SALE_PAGAMENTI, tmpPagamenti)
    }
  },
  [Actions.REMOVE_SHOPPING_BAGS]: async (
    context: ActionContext<SalesState, RootState>,
    progressivo: number,
  ) => {
    if (context.state.currentSale.pagamenti) {
      await context.dispatch(Actions.REMOVE_PAYMENT, progressivo)
      const tmpValidator = store.state.payments.payments_validator
      delete tmpValidator[progressivo]
      store.commit(PaymentsMutations.STORE_VALIDATION_PAGAMENTI, tmpValidator)
    }
  },
  [Actions.CONFIRM_SHOPPING_BAGS]: (
    context: ActionContext<SalesState, RootState>,
    deletion: boolean,
  ) => {
    if (context.state.currentSale.pagamenti) {
      const tmpPayments_validator = {
        ...store.state.payments.payments_validator,
      }

      const tmpPagamenti = context.state.currentSale.pagamenti.filter((p) => {
        if (p.codice_movimento === TipiMovimenti.CONTABILITA_SHOPPING_BAG) {
          if (
            (tmpPayments_validator[String(p.progressivo)] === null &&
              deletion) ||
            (tmpPayments_validator[String(p.progressivo)] === false &&
              !deletion)
          ) {
            delete tmpPayments_validator[String(p.progressivo)]
            return false
          }
          tmpPayments_validator[String(p.progressivo)] = true
        }
        return true
      })
      context.commit(Mutations.STORE_SALE_PAGAMENTI, tmpPagamenti)
      store.commit(
        PaymentsMutations.STORE_VALIDATION_PAGAMENTI,
        tmpPayments_validator,
      )
    }
  },
  [Actions.RESET_SHOPPING_BAGS]: (
    context: ActionContext<SalesState, RootState>,
  ): void => {
    const tmpPayments_validator = {
      ...store.state.payments.payments_validator,
    }
    if (context.state.currentSale.pagamenti) {
      const tmpPagamenti = context.state.currentSale.pagamenti.filter(
        (p) =>
          p.codice_movimento !== TipiMovimenti.CONTABILITA_SHOPPING_BAG ||
          (p.codice_movimento === TipiMovimenti.CONTABILITA_SHOPPING_BAG &&
            tmpPayments_validator[String(p.progressivo)] !== null),
      )
      context.state.currentSale.pagamenti.forEach((p) => {
        if (p.codice_movimento === TipiMovimenti.CONTABILITA_SHOPPING_BAG) {
          if (tmpPayments_validator[String(p.progressivo)] === null) {
            delete tmpPayments_validator[String(p.progressivo)]
          } else {
            tmpPayments_validator[String(p.progressivo)] = false
          }
        }
      })

      context.commit(Mutations.STORE_SALE_PAGAMENTI, tmpPagamenti)
      store.commit(
        PaymentsMutations.STORE_VALIDATION_PAGAMENTI,
        tmpPayments_validator,
      )
    }
  },
  [Actions.CONFIRM_SHIPPING_COST]: (
    context: ActionContext<SalesState, RootState>,
    deletion: boolean,
  ) => {
    if (context.state.currentSale.pagamenti) {
      const tmpPayments_validator = {
        ...store.state.payments.payments_validator,
      }

      const tmpPagamenti = context.state.currentSale.pagamenti.filter((p) => {
        if (p.codice_movimento === TipiMovimenti.CONTABILITA_SPEDIZIONE) {
          if (
            (tmpPayments_validator[String(p.progressivo)] === null &&
              deletion) ||
            (tmpPayments_validator[String(p.progressivo)] === false &&
              !deletion)
          ) {
            delete tmpPayments_validator[String(p.progressivo)]
            return false
          }
          tmpPayments_validator[String(p.progressivo)] = true
        }
        return true
      })
      context.commit(Mutations.STORE_SALE_PAGAMENTI, tmpPagamenti)
      store.commit(
        PaymentsMutations.STORE_VALIDATION_PAGAMENTI,
        tmpPayments_validator,
      )
    }
  },
  [Actions.RESET_SHIPPING_COST]: (
    context: ActionContext<SalesState, RootState>,
  ): void => {
    const tmpPayments_validator = {
      ...store.state.payments.payments_validator,
    }
    if (context.state.currentSale.pagamenti) {
      const tmpPagamenti = context.state.currentSale.pagamenti.filter(
        (p) =>
          p.codice_movimento !== TipiMovimenti.CONTABILITA_SPEDIZIONE ||
          (p.codice_movimento === TipiMovimenti.CONTABILITA_SPEDIZIONE &&
            tmpPayments_validator[String(p.progressivo)] !== null),
      )
      context.state.currentSale.pagamenti.forEach((p) => {
        if (p.codice_movimento === TipiMovimenti.CONTABILITA_SPEDIZIONE) {
          if (tmpPayments_validator[String(p.progressivo)] === null) {
            delete tmpPayments_validator[String(p.progressivo)]
          } else {
            tmpPayments_validator[String(p.progressivo)] = false
          }
        }
      })

      context.commit(Mutations.STORE_SALE_PAGAMENTI, tmpPagamenti)
      store.commit(
        PaymentsMutations.STORE_VALIDATION_PAGAMENTI,
        tmpPayments_validator,
      )
    }
  },

  [Actions.SET_TAILORING_VALIDATIONS_FALSE]: (
    context: ActionContext<SalesState, RootState>,
  ) => {
    if (context.state.currentSale.pagamenti) {
      const tailoring = context.state.currentSale.pagamenti.filter(
        (p) =>
          p.progressivo_capo === 0 &&
          (p.codice_movimento === TipiMovimenti.CONTABILITA_SARTORIA ||
            p.codice_movimento === TipiMovimenti.CONTABILITA_SARTORIA_OMAGGIO),
      )
      const tmpPayments_validator = store.state.payments.payments_validator
      tailoring.forEach((t) => {
        if (t.progressivo && tmpPayments_validator[t.progressivo] === null) {
          tmpPayments_validator[t.progressivo] = false
        }
      })
      store.commit(
        PaymentsMutations.STORE_VALIDATION_PAGAMENTI,
        tmpPayments_validator,
      )
    }
  },
  [Actions.SET_DATA_VISIBILITY]: async (
    context: ActionContext<SalesState, RootState>,
  ): Promise<void> => {
    const [statisticsResponse, statisticsInsegnaResponse] = await Promise.all([
      consumerDataApi.apiV1ConsumersPkConsumerStatisticsGet(
        context.getters[Getters.GET_CONSUMER_ID],
      ),
      consumerDataApi.apiV1ConsumersPkConsumerStatisticsInsegnaGet(
        context.getters[Getters.GET_CONSUMER_ID],
      ),
    ])

    const payload = {
      statistics: statisticsResponse.data,
      statisticsInsegna: statisticsInsegnaResponse.data,
    } as Statistics

    context.commit(Mutations.STORE_DATA_VISIBILITY, payload)
  },
  [Actions.ACTIVATE_GIFT_CARDS]: async (
    context: ActionContext<SalesState, RootState>,
    giftCards: GiftCardForm[],
  ): Promise<void> => {
    context.commit(Mutations.SET_GIFT_CARD_SAVE_IN_PROCESS, true)
    const payload: GiftCards = {
      data: giftCards
        .filter((g) => !g.activated && g.amount && g.barcode?.length)
        ?.map((g) => ({
          type: 'gift_card',
          attributes: {
            barcode: g.barcode,
            row_num: g.row_num,
            importo: g.amount,
          },
        })),
    }
    if (!payload.data?.length) {
      context.commit(Mutations.SET_GIFT_CARD_SAVE_IN_PROCESS, false)
      return Promise.resolve()
    }
    let idTransazioneGenerated = false
    if (!context.state.currentSale.id_transazione) {
      await context.dispatch(Actions.GET_NEW_TRANSACTION_ID, true)
      idTransazioneGenerated = true
    }
    try {
      const result = await giftCardApi.apiV1GiftCardsActivateSaleIdPost(
        `${context.rootState.configs.currentStore?.STORE_CODE},${String(
          context.state.currentSale.id_transazione,
        )}`,
        payload,
      )
      ;(
        result.data.data as {
          attributes: {
            status: string
            message: string
            importo: number
            barcode: string
            data: { [k: string]: string }
          }
        }[]
      )?.forEach((g) => {
        if (g.attributes.status === 'error') {
          context.dispatch(
            NotificationsActions.NOTIFY_ERROR,
            g.attributes.message,
            { root: true },
          )
          if (idTransazioneGenerated) {
            context.dispatch(Actions.CLEAR_TRANSACTION_ID, true)
          }
          return Promise.reject(new Error('status === error'))
        } else if (g.attributes.status === 'created') {
          idTransazioneGenerated = false
          context.dispatch(Actions.ADD_PAYMENT, {
            id_transazione: context.state.currentSale.id_transazione,
            cod_negozio: context.rootState.configs.currentStore?.STORE_CODE,
            codice_stato: context.state.currentSale.codice_stato,
            progressivo_capo: 0,
            codice_movimento: TipiMovimenti.CONTABILITA_GIFT,
            cod_operazione: 'GIFT_CARD',
            dati_operazione: JSON.stringify({
              iva: 0,
              dati_gift_card: g.attributes.data,
            }),
            importo_iniziale: g.attributes.importo,
            importo_finale: g.attributes.importo,
            divisa: context.rootState.configs.currentStore?.CURRENCY_CODE,
            nota: '',
            barcode: g.attributes.barcode,
            reso: 0, // todo RESI
            data_creazione: format(new Date(), ISO8601DateTimeStandard),
            data_modifica: format(new Date(), ISO8601DateTimeStandard),
            tipo_applicazione: 'POSWEB',
            id_postazione: context.rootState.configs.setup?.cash_id,
          })
        }
      })
      context.commit(Mutations.SET_GIFT_CARD_SAVE_IN_PROCESS, false)
      return Promise.resolve()
    } catch (err: any) {
      if (err?.response?.data?.errors?.length) {
        await store.dispatch(
          NotificationsActions.NOTIFY_ERROR,
          extractErrorMessage(
            err?.response?.data?.errors[0].detail?.message || '',
          ),
          { root: true },
        )
      }
      context.commit(Mutations.SET_GIFT_CARD_SAVE_IN_PROCESS, false)
      return Promise.reject(err)
    }
  },
  [Actions.GET_NEW_TRANSACTION_ID]: async (
    context: ActionContext<SalesState, RootState>,
    preserveSale = false,
  ): Promise<string | undefined> => {
    try {
      const idTransazione = await getNewTransactionId()
      if (idTransazione) {
        context.commit(Mutations.STORE_ID_TRANSAZIONE, idTransazione)
        context.commit(
          Mutations.STORE_CREATION_DATE,
          format(new Date(), ISO8601DateTimeStandard),
        )
      }
      return idTransazione
    } catch (rawError) {
      const error = rawError as AxiosError

      if (!preserveSale) {
        context.dispatch(
          NotificationsActions.NOTIFY_ERROR,
          'pos_sale.error_new_transaction_sale',
          { root: true },
        )
      } else {
        const msg =
          i18n.global.t('pos_sale.error_new_transaction_sale') +
          (error?.response?.data?.errors?.length
            ? '\n' +
              extractErrorMessage(error?.response?.data?.errors[0]?.detail)
            : '')
        context.dispatch(NotificationsActions.NOTIFY_ERROR, msg, {
          root: true,
        })
      }
    }
  },
  [Actions.CLEAR_TRANSACTION_ID]: async (
    context: ActionContext<SalesState, RootState>,
    preserveSale = false,
  ): Promise<string | undefined> => {
    try {
      await context.commit(Mutations.CLEAR_ID_TRANSAZIONE)
    } catch (rawError) {
      const error = rawError as AxiosError

      if (!preserveSale) {
        context.dispatch(
          NotificationsActions.NOTIFY_ERROR,
          'pos_sale.error_new_transaction_sale',
          { root: true },
        )
      } else {
        const msg =
          i18n.global.t('pos_sale.error_new_transaction_sale') +
          (error?.response?.data?.errors?.length
            ? '\n' +
              extractErrorMessage(error?.response?.data?.errors[0]?.detail)
            : '')
        context.dispatch(NotificationsActions.NOTIFY_ERROR, msg, {
          root: true,
        })
      }
    }
  },
  /**
   * Actions.PAY_GIFT_CARD asincrona per effettuare un pagamento con una carta regalo.
   * Usata alla conferma della Gift nella spalla pagamenti
   * accetta:
   * @param context
   * @param payload oggetto con informazioni sulla carta regalo, l'importo del pagamento e il progressivo.
   * restituisce una Promise vuota.
   * effetti:
   * • API call: salesApi.apiV1SalesSaleIdPaymentsGiftCardGiftCodePost / salesApi.apiV1SalesSaleIdPaymentsGiftCardGiftCodePatch
   * • action.ADD_PAYMENT_DATA
   * • mutation.STORE_ID_TRANSAZIONE
   * • mutation.STORE_CREATION_DATE
   * • mutation.GIFTCARD_PAYMENT_ONGOING
   */
  [Actions.PAY_GIFT_CARD]: async (
    context: ActionContext<SalesState, RootState>,
    payload: {
      giftCard: GiftCardInformationDataAttributes
      amount: number
      progressivo: number
    },
  ): Promise<void> => {
    const idTransazione = context.state.currentSale.id_transazione || 0

    // Costruisci l'id utilizzando il codice del negozio corrente e l'id della transazione.
    const saleId = `${
      context.rootState.configs.currentStore?.STORE_CODE
    },${String(idTransazione)}`
    const payment = context.state.currentSale.pagamenti?.find(
      (p) => p.progressivo === payload.progressivo,
    )
    if (!payment) {
      return
    }
    context.state.currentSale.capi?.map((d) => {
      if ('error_code' in d) {
        delete d.error_code
      }
    })
    // Costruisci l'oggetto "burnGiftCard" per effettuare il pagamento
    // inglobando context.state.currentSale.
    // passato nel try, più sotto
    const burnGiftCard: BurnGiftCard = {
      data: {
        type: 'burn_gift_card',
        id: saleId,
        attributes: {
          importo: payload.amount,
          barcode: payload.giftCard.CardCode,
          is_store_credit: 0, // if type === STORE_CREDIT_CORPORATE then 1
          ...context.state.currentSale,
          pagamenti: [payment],
          id_transazione: idTransazione,
          om_num_order: undefined,
        },
      },
    }
    // handleResult prepara il risultato del pagamento, ottenuto dalla salesApiCall
    // più sotto nel try
    const handleResult = (r: AxiosResponse<ReturnBurnGiftCard>) => {
      const responseData = r.data.data

      if (!responseData || responseData.length === 0) {
        return
      }
      const esito = (responseData[1] as GiftCardData)
        ?.attributes as GiftCardData1DataAttributes & {
        message?: string
      }
      if (esito?.Status === 'ERROR') {
        context.commit(
          Mutations.STORE_ID_TRANSAZIONE,
          (responseData[0] as ApiV1SalesData)?.attributes?.id_transazione,
        )
        context.commit(
          Mutations.STORE_CREATION_DATE,
          (responseData[0] as ApiV1SalesData)?.attributes?.data_creazione,
        )
        store.commit(GiftMutations.GIFTCARD_PAYMENT_ONGOING, false)
        context.dispatch(NotificationsActions.NOTIFY_ERROR, esito?.message, {
          root: true,
        })
      } else {
        // Aggiorna lo stato della transazione e aggiungi i dati del pagamento.
        const saleResult = responseData[0] as ManageSaleReturnData
        store.commit(GiftMutations.GIFTCARD_PAYMENT_ONGOING, false)
        context.commit(
          Mutations.STORE_ID_TRANSAZIONE,
          saleResult?.attributes?.id_transazione,
        )
        const payments = saleResult.attributes?.pagamenti

        const gift = payments?.length ? payments[payments?.length - 1] : {}
        if (!gift) {
          return
        }
        context.commit(Mutations.STORE_CREATION_DATE, gift.data_creazione)
        // Aggiungi i dati del pagamento alla lista del currentSale.pagamenti.
        context.dispatch(Actions.ADD_PAYMENT_DATA, {
          progressivo: payload.progressivo,
          formData: gift.dati_operazione,
          importo: gift.importo_iniziale,
          id_transazione: gift.id_transazione,
          giftCardBarcode: payload.giftCard.CardCode,
        })
      }
    }
    // esegui il pagamento
    try {
      const salesApiCall =
        idTransazione === 0
          ? salesApi.apiV1SalesSaleIdPaymentsGiftCardGiftCodePost
          : salesApi.apiV1SalesSaleIdPaymentsGiftCardGiftCodePatch

      const result = await salesApiCall(
        saleId,
        payload.giftCard.CardCode || '',
        burnGiftCard,
      )
      // Gestisci la risposta
      handleResult(result)
    } catch (error: unknown) {
      await store.dispatch(
        NotificationsActions.NOTIFY_ERROR,
        error.response.data.errors[0].detail,
      )
    }
  },
  [Actions.UPDATE_SUSPENDED_CREDIT]: (
    context: ActionContext<SalesState, RootState>,
    payload: SuspendedCreditForm,
  ) => {
    context.commit(Mutations.UPDATE_SUSPENDED_CREDIT, payload)
  },
  [Actions.FETCH_SALES_HEADER_ICONS]: async (
    context: ActionContext<SalesState, RootState>,
  ) => {
    const pkConsumer = context.state.consumer?.pk_consumer ?? ''
    const filterDataA = store.getters[ConfigGetters.GET_CUSTOM_DATE]
      ? store.getters[ConfigGetters.GET_CUSTOM_DATE]
      : undefined

    const response = await consumerDataApi.apiV1ConsumersIconsPkConsumerGet(
      pkConsumer,
      filterDataA,
    )

    const icons = response?.data?.data?.attributes
    context.commit(Mutations.SET_SALES_HEADER_ICONS, icons)
  },
  [Actions.UPDATE_PAYMENT_DATA]: (
    context: ActionContext<SalesState, RootState>,
    payload: { changed: { id: string; value: string }; paymentKey: string },
  ) => {
    context.commit(Mutations.UPDATE_PAYMENT_DATA, payload)
  },
  [Actions.UPDATE_FULL_PAYMENT_DATA]: (
    context: ActionContext<SalesState, RootState>,
    payload: { paymentForm: PaymentMethodForm; paymentKey: string },
  ) => {
    context.commit(Mutations.UPDATE_FULL_PAYMENT_DATA, payload)
  },
  [Actions.CLOSE_SALE]: async (
    context: ActionContext<SalesState, RootState>,
    payload: {
      invoiceIsValid: boolean
      datiDocumenti:
        | SaleTicketDocument
        | FatturaForm
        | FatturaUEForm
        | FatturaAmbForm
        | FatturaTaxfreeForm
      isInDepositFlow: boolean
    },
  ): Promise<boolean> => {
    const selectedDocument = context.state.currentSale.cod_documento
    const allowedDocuments = [
      TipiDocumento.SALE_TICKET,
      TipiDocumento.SALE_INVOICE,
      TipiDocumento.SALE_INVOICE_EU,
      TipiDocumento.SALE_INVOICE_AMB,
      TipiDocumento.SALE_TAXFREE,
    ]
    completePurchaseEvent({
      id: context.state.currentSale.id_transazione || 0,
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      purchaseType:
        context.state.cartType.id === '001' ||
        context.state.laybyInfo.importo_mancante === 0
          ? 'standard'
          : 'acconto',
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      products:
        context?.state?.currentSale?.capi?.map((item) => {
          return {
            quantity: 1, // Quantity is set to 1 because the products can have different details even sku is equal
            product: {
              sku: item.sku as string,
              model: (item.nome || '') as string,
              price: {
                currentPrice: (item.importo_finale?.toString() || '') as string,
                fullPrice: (item.importo_iniziale?.toString() || '') as string,
                currency: store.state.configs.currentStore
                  ?.CURRENCY_SIGN as string,
                discount: (item.sconto || 0) as number,
              },
            },
          }
        }) || [],
      purchaseTransaction: {
        revenue: context.state.currentSale.importo_finale ?? 0,
        currencyCode: store.state.configs.currentStore?.CURRENCY_SIGN as string,
      },
    })
    if (
      payload.invoiceIsValid &&
      allowedDocuments.includes(selectedDocument as TipiDocumento)
    ) {
      await store.dispatch(
        SalesActions.SET_DOCUMENTO_DATI,
        payload.datiDocumenti,
      )
    }
    let saleRegistrationIsOk
    if (payload.isInDepositFlow) {
      await store.dispatch(SalesActions.REMOVE_CAPI_LAYBY_DELETE)
      saleRegistrationIsOk = await store.dispatch(SalesActions.CREATE_SALE)
      store.dispatch(SalesActions.SET_LAYBY_STATUS, LaybyStatusType.RECAP)
      return saleRegistrationIsOk
    }
    if (store.getters[SalesGetters.IS_FREE_RETURN]) {
      await store.dispatch(SalesActions.SET_DATI_STORNO_CURRENTSALE)
    }

    const data_creazione = store.getters[ConfigGetters.GET_CUSTOM_DATE]
      ? dateToISODayWithTime(
          unformatMMFGDate(store.getters[ConfigGetters.GET_CUSTOM_DATE]),
        )
      : dateToISODayWithTime(new Date())
    await store.dispatch(SalesActions.STORE_CREATION_DATE, data_creazione)

    if (
      Object.keys(context.state.currentSale).includes('id_transazione') &&
      typeof context.state.currentSale.id_transazione !== 'undefined'
    ) {
      saleRegistrationIsOk = await store.dispatch(SalesActions.UPDATE_SALE)
      return saleRegistrationIsOk
    }
    store.dispatch(SalesActions.UPDATE_FULL_PAYMENT_DATA, {
      paymentForm: {},
      paymentKey: PaymentKeys.CHEQUE,
    })
    store.dispatch(SalesActions.UPDATE_FULL_PAYMENT_DATA, {
      paymentForm: {},
      paymentKey: PaymentKeys.CASH,
    })
    saleRegistrationIsOk = await store.dispatch(SalesActions.CREATE_SALE)
    return saleRegistrationIsOk
  },
  [Actions.SET_CART_TYPE]: (
    context: ActionContext<SalesState, RootState>,
    payload: CartType,
  ) => {
    context.commit(Mutations.SET_CART_TYPE, payload)
  },
  [Actions.SET_SELECTED_CART_TYPE]: (
    context: ActionContext<SalesState, RootState>,
    payload: CartType,
  ) => {
    context.commit(LaybyMutations.RESET_LAYBY_DATA)
    context.commit(Mutations.SET_SELECTED_CART_TYPE, payload)
  },
  [Actions.RESET_CART_TYPE]: (
    context: ActionContext<SalesState, RootState>,
  ) => {
    context.commit(Mutations.RESET_CART_TYPE)
  },

  [Actions.SET_TOTAL_PRICE_DEPOSIT]: (
    context: ActionContext<SalesState, RootState>,
    payload: number,
  ) => {
    context.commit(Mutations.SET_TOTAL_PRICE_DEPOSIT, payload)
  },
  [Actions.SET_TOTAL_PRICE_DEPOSIT_PAYMENT_METHOD]: (
    context: ActionContext<SalesState, RootState>,
    payload: number,
  ) => {
    context.commit(Mutations.SET_TOTAL_PRICE_DEPOSIT_PAYMENT_METHOD, payload)
  },

  [Actions.CLEAR_SALE_CAPI]: (
    context: ActionContext<SalesState, RootState>,
  ) => {
    context.commit(Mutations.CLEAR_SALE_CAPI)
    context.commit(Mutations.RESET_INFO_GIACENZA_CAPI)
  },
  [Actions.CHECK_DATI_ANTIRICICLAGGIO]: async (
    context: ActionContext<SalesState, RootState>,
    datiAntiriciclaggio: ApiV1SalesCheckDatiAntiriciclaggioDataAttributes,
  ) => {
    const payload = {
      data: {
        type: 'dati_antiriciclaggio',
        attributes: datiAntiriciclaggio,
      },
    }
    const response =
      await salesApi.apiV1SalesCheckDatiAntiriciclaggioPost(payload)
    if (response.data.data?.attributes?.status === 'SUCCESS') {
      context.commit(Mutations.CHECK_ANTIRICICLAGGIO_RESULT, true)

      const printResultOk = await context.dispatch(
        Actions.STAMPA_RIEPILOGO_ANTIRICICLAGGIO,
        datiAntiriciclaggio,
      )
      if (printResultOk) {
        store.dispatch(
          NotificationsActions.NOTIFY_SUCCESS,
          i18n.global.t('pos_common.success_doc_print'),
        )
      }
    } else if (response.data.data?.attributes?.status === 'ERROR') {
      context.commit(Mutations.CHECK_ANTIRICICLAGGIO_RESULT, false)
      store.dispatch(
        NotificationsActions.NOTIFY_ERROR,
        i18n.global.t('pos_common.print_fail'),
      )
      context.dispatch(Actions.UPDATE_PAYMENT_DATA, {
        changed: {
          id: 'printErrors',
          value: response.data.data?.attributes?.form_errors,
        },
        paymentKey: 'cash',
      })
    }
  },
  [Actions.STAMPA_RIEPILOGO_ANTIRICICLAGGIO]: async (
    context: ActionContext<SalesState, RootState>,
    datiAntiriciclaggio: ApiV1SalesCheckDatiAntiriciclaggioDataAttributes,
  ) => {
    const payload = {
      data: {
        type: 'dati_antiriciclaggio',
        attributes: datiAntiriciclaggio,
      },
    }
    const response =
      await salesApi.apiV1SalesStampaRiepilogoAntiriciclaggioPost(payload)

    if (response.data.data?.attributes?.status === 'SUCCESS') {
      context.dispatch(Actions.UPDATE_PAYMENT_DATA, {
        id: 'hasPrinted',
        value: true,
      })
      return true
    } else if (response.data.data?.attributes?.status === 'ERROR') {
      store.dispatch(
        NotificationsActions.NOTIFY_ERROR,
        i18n.global.t('pos_common.print_fail'),
      )
      return false
    }
  },

  [Actions.SET_GIFT_RECEIPT]: (
    context: ActionContext<SalesState, RootState>,
    payload: boolean,
  ): void => {
    context.commit(Mutations.SET_GIFT_RECEIPT, payload ? 1 : 0)
  },

  [Actions.SET_USED_CASH]: (
    context: ActionContext<SalesState, RootState>,
    payload: UsedCash,
  ): void => {
    context.commit(Mutations.SET_USED_CASH, payload)
  },
  [Actions.CLEAR_USED_CASH]: (
    context: ActionContext<SalesState, RootState>,
  ): void => {
    context.commit(Mutations.CLEAR_USED_CASH)
  },
  [Actions.SET_CODICE_MOVIMENTO]: (
    context: ActionContext<SalesState, RootState>,
    payload: TipiVendita | undefined,
  ): void => {
    context.commit(Mutations.SET_CODICE_MOVIMENTO, payload)
  },
  [Actions.SET_DATI_STORNO]: (
    context: ActionContext<SalesState, RootState>,
    payload: datiStornoD,
  ): void => {
    context.commit(Mutations.SET_DATI_STORNO, payload)
  },
  [Actions.SET_DATI_STORNO_CURRENTSALE]: (
    context: ActionContext<SalesState, RootState>,
  ): void => {
    context.commit(
      Mutations.SET_DATI_STORNO_CURRENTSALE,
      context.state.dati_storno,
    )
  },
  [Actions.SET_TOTAL_CAPI]: (
    context: ActionContext<SalesState, RootState>,
  ): void => {
    context.commit(Mutations.SET_TOTAL_CAPI)
  },
  [Actions.SET_DISABLED_CONFIRM]: (
    context: ActionContext<SalesState, RootState>,
    payload: boolean,
  ): void => {
    context.commit(Mutations.SET_DISABLED_CONFIRM, payload)
  },
  [Actions.CHECK_PRODUCTS_STOCK]: (
    context: ActionContext<SalesState, RootState>,
  ): void => {
    context.commit(Mutations.CHECK_PRODUCTS_STOCK)
  },
  [Actions.SET_CAN_CHANGE_CART_TYPE]: (
    context: ActionContext<SalesState, RootState>,
    payload: CanChangeCartTypeD,
  ): void => {
    context.commit(Mutations.SET_CAN_CHANGE_CART_TYPE, payload)
  },
  [Actions.SET_INFO_GIACENZA_CAPI]: (
    context: ActionContext<SalesState, RootState>,
    payload: InfoGiacenzaCapiD,
  ): void => {
    context.commit(Mutations.SET_INFO_GIACENZA_CAPI, payload)
  },
  [Actions.REMOVE_INFO_GIACENZA_CAPI]: (
    context: ActionContext<SalesState, RootState>,
    payload: string,
  ): void => {
    context.commit(Mutations.REMOVE_INFO_GIACENZA_CAPI, payload)
  },
  [Actions.RESET_INFO_GIACENZA_CAPI]: (
    context: ActionContext<SalesState, RootState>,
  ): void => {
    context.commit(Mutations.RESET_INFO_GIACENZA_CAPI)
  },
  [Actions.STORE_ID_TRANSAZIONE]: (
    context: ActionContext<SalesState, RootState>,
    id: string,
  ): void => {
    context.commit(Mutations.STORE_ID_TRANSAZIONE, id)
  },
  [Actions.STORE_CREATION_DATE]: (
    context: ActionContext<SalesState, RootState>,
    date: string,
  ): void => {
    context.commit(Mutations.STORE_CREATION_DATE, date)
  },
  [Actions.ADD_COD_COMMESSA](
    context: ActionContext<SalesState, RootState>,
    payload: string,
  ): void {
    context.commit(Mutations.ADD_COD_COMMESSA, payload)
  },
}
