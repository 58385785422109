import { ConsumerTypeEnum, StockIcon } from '@/addons/enums'
import { getBaseURL, isCapoB2E } from '@/addons/functions'
import {
  ApiV1SalesDataAttributesCapi,
  GetConsumerAttributes,
  GetSkuDataAttributes,
  GetSkuGiacenzaAttributes,
} from '@/api'
import {
  BarcodeScanningMode,
  BarcodeValidatorFunction,
} from '@/abs-core/features/barcode-scanner/barcode-interfaces'
import store from '@/store'
import { ConfigGetters } from '@/store/configs-store'
import { NotificationsActions } from '@/store/notifications-store'
import { SalesGetters } from '@/store/sales/sales-store'
import { isConfigTruthy } from './configs'

type CustomCapoT = ApiV1SalesDataAttributesCapi & {
  giacenza: GetSkuGiacenzaAttributes
}

/**
 * Given a cart item, it returns the name of the icon associated
 * to the stock levels for that product.
 * @param capo A cart item
 */
export function stockIcon(capo: CustomCapoT) {
  if (typeof capo.progressivo === 'undefined') {
    return StockIcon.EMPTY
  }

  if (capo?.giacenza?.giac_offline) {
    return StockIcon.STOCKNOTRETRIEVABLE
  }
  if (capo.flag_promo === 1 && !capo.tipo_importo) {
    return StockIcon.PROMONOEDIT
  }

  const product = store.getters[SalesGetters.GET_PRODUCT](
    capo.progressivo,
  ) as GetSkuDataAttributes
  if (!product) {
    return StockIcon.PENDING
  }

  if (isConfigTruthy('BLOCK_SALES_NO_PRICE_NO_STOCK')) {
    if (product.prezzo_iniziale === 0 && product.prezzo_effettivo === 0) {
      store.dispatch(
        NotificationsActions.NOTIFY_ERROR,
        'pos_stock.block_no_stock',
      )
    }
  }
  if (product.error_code) {
    if (product.error_code === 'ANAGRAFICA_INESISTENTE') {
      return StockIcon.NODATASTOCK
    }
  }
  if (!Object.keys(product.listini).length) {
    return StockIcon.NOLISTDATA
  }
  const customData = (
    product as GetSkuDataAttributes & {
      custom_data?: string
    }
  )?.custom_data
  if (isCapoB2E(customData || '{}')) {
    return StockIcon.TRUCK
  }

  if (product.flag_created) {
    return StockIcon.PERSON
  }

  if (isConfigTruthy('STOCK_CHECK_ENABLED')) {
    if (product.giacenza[0]?.giacenza <= 0) {
      return StockIcon.TRIANGLE
    }
  }

  return StockIcon.EMPTY
}

// TODO: STAB: modificare questa funzione affinchè ti dica anche se la consumatrice è anonima
export function getConsumerType(
  consumer: GetConsumerAttributes,
): ConsumerTypeEnum {
  if (consumer?.locale === 0 || consumer?.locale === 1) {
    return ConsumerTypeEnum.STORE
  }
  if (consumer?.locale === 2 && consumer?.stessa_insegna === 1) {
    return ConsumerTypeEnum.BRAND
  }

  return ConsumerTypeEnum.GROUP
}

/**
 * A barcode validator function. This can be used to ensure we are reading the correct one.
 * For example, this is the case with Marella group SKUs.
 * @param barcode Barcode to validate
 */
export const skuBarcodeValidator: BarcodeValidatorFunction = (
  barcode: string | undefined,
): Promise<boolean> => {
  if (!barcode) {
    return Promise.resolve(false)
  }

  if (isConfigTruthy('SKU_GRUPPO_ENABLED')) {
    const storeSign = store.getters[ConfigGetters.GET_STORE_CONFIGS]?.STORE_SIGN

    if (storeSign === 'MA') {
      // Marella's group SKUs starts with the 'MA' prefix and are followed by 11 alphanumeric chars.
      return Promise.resolve(
        barcode.length === 13 && barcode.startsWith(storeSign),
      )
    }
  }

  // TODO: Change this to the configurable length
  return Promise.resolve(barcode.length === 14)
}

/**
 * Returns whether we should read a single barcode at a time, or if we should read multiple ones.
 */
export function barcodeReadingMode(): BarcodeScanningMode {
  // return 'multiple'
  return isConfigTruthy('SALES_READ_MULTIPLE_BARCODES') ? 'multiple' : 'single'
}

/**
 * A static class to play sounds given a file path.
 * By using this class, we automatically correctly handle these medias on iOS.
 */
export abstract class SoundCueHelper {
  private static audio = new Audio()

  /**
   * Plays a sound given its' path
   * @param audioFilePath File path
   */
  static play(audioFilePath: string) {
    this.audio.src = audioFilePath
    this.audio.play()
  }

  /**
   * Stops the audio reproduction.
   */
  static stop() {
    this.audio.pause()
    this.audio.src = ''
  }

  /**
   * Reproduces the audio file appropriate for the added product.
   * If it has been successully added to the card, we reproduce a sound.
   * Otherwise we play an "error" sound.
   * @param wasSuccessfullyAdded Has been successfully added?
   */
  static onProductAdded(wasSuccessfullyAdded: boolean) {
    const audioFile = wasSuccessfullyAdded
      ? `${getBaseURL()}/assets/audio/sound-success.wav`
      : `${getBaseURL()}/assets/audio/sound-error.wav`

    SoundCueHelper.play(audioFile)
  }
}
