import { roundDecimals } from '@/addons/adjustments'
import { parseHTML } from '@/addons/functions'
import i18n from '@/addons/i18n'
import store from '@/store'
import { NumberFormat } from '@intlify/core-base'

/**
 * Returns a formatted and rounded currency value based on the value passed.
 * @param value - The string or number value to format.
 * @param showCurrencySymbol - Whether to show the currency symbol. Default is true.
 * @param noRounding - Whether to round the value or not.
 * @returns The formatted currency value as a string.
 */
export function formatCurrency(
  value: string | number = 0,
  showCurrencySymbol = true,
  noRounding = false,
): string {
  const numberValue = getParsedNumber(value)
  return formatCustomSeparators(
    noRounding ? numberValue : roundDecimals(numberValue),
    showCurrencySymbol,
  )
}

/**
 * Returns a formatted currency value (without rounding - only for payments).
 * @param value - The string or number value to format.
 * @returns The formatted currency value as a string.
 */
export function formatCurrencyWithoutRounding(value: string | number) {
  const numberValue = getParsedNumber(value)

  return formatCustomSeparators(numberValue)
}

/**
 * Returns an empty price string with the configured currency sign.
 * @returns The empty price string with the currency sign.
 */
export function getEmptyPrice(): string {
  // workaround to parse currency HTML code
  return parseHTML(`${store.state.configs.currentStore?.CURRENCY_SIGN} -`)
}

/**
 * Returns a formatted currency value with custom thousand and decimal separators.
 * @param toFormat - The number to format.
 * @param showCurrencySymbol - Whether to show the currency symbol. Default is true.
 * @returns The formatted currency value as a string.
 */
function formatCustomSeparators(
  toFormat: number,
  showCurrencySymbol = true,
): string {
  const formatter = new Intl.NumberFormat(
    'en-US',
    (i18n.global.getNumberFormat('en') as NumberFormat).currency,
  )
  const thousandSep = store?.state?.configs?.currentStore?.THOUSAND_SEPARATOR
  const decimalSep = store?.state?.configs?.currentStore?.DECIMAL_SEPARATOR

  // Controlliamo se il numero è negativo
  const isNegative = toFormat < 0
  // Valore assoluto per la formattazione
  const absValue = Math.abs(toFormat)

  const parts = formatter.formatToParts(absValue)

  // Formattiamo la parte numerica con i separatori personalizzati
  let numericPart = ''
  parts.forEach((part) => {
    if (part.type === 'decimal') {
      numericPart += decimalSep
    } else if (part.type === 'group') {
      numericPart += thousandSep
    } else if (part.type !== 'currency') {
      numericPart += part.value
    }
  })

  // Montiamo il risultato finale con i vari pezzi: simbolo + segno + numeri formattati
  let result = ''

  // 1. currency
  if (showCurrencySymbol) {
    const currencySymbol =
      store?.state?.configs?.currentStore?.CURRENCY_SIGN || '$'
    result += parseHTML(currencySymbol) + ' '
  }
  // 2. sign
  if (isNegative) {
    result += '-'
  }
  // 3. numbers
  result += numericPart

  return result
}

/**
 * Parses a string representing a price to a number.
 *
 * This function replaces commas with dots and converts the resulting string to a float.
 *
 * @param {string | number} value - The value to be parsed. It can be a string or a number.
 * @returns {number} - The parsed number. Returns 0 if the input value is falsy.
 */
export function getParsedNumber(value: string | number) {
  if (!value) {
    return 0
  }
  // TODO: THIS LOGIC IS USELESS, WE SHOULD REMOVE IT
  if (typeof value === 'string') {
    let valueWithoutComma = value.replace(/,/g, '.')
    let digitsAfterPoint = 0
    // If the original value has a point (.) as thousands separator, we remove it
    if (value.includes('.')) {
      digitsAfterPoint = value.toString().split('.')[1].split(',')[0].length
      if (digitsAfterPoint === 3) {
        // value is a number with point (.) as thousands separator -> we remove the separator
        valueWithoutComma = value.replace(/\./g, '').replace(/,/g, '.')
      }
    }
    return parseFloat(valueWithoutComma)
  }
  return value
}
