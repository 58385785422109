import {
  AccontiApiFactory,
  AggiornamentoPrezziApiFactory,
  B2ERefundsApiFactory,
  CatalogApiFactory,
  ChiusuraGiornalieraApiFactory,
  Configuration,
  ConsumerApiFactory,
  CouponsApiFactory,
  CreditCardApiFactory,
  GiftCardsApiFactory,
  ItemsApiFactory,
  ListaVenditeApiFactory,
  OMTokenApiFactory,
  PaymentsApiFactory,
  RFIDApiFactory,
  PettyCashApiFactory,
  ReintegriApiFactory,
  ResiApiFactory,
  SalesApiFactory,
  SessionApiFactory,
  SospesiApiFactory,
  StoreDataApiFactory,
  StoreManagementApiFactory,
  SystemApiFactory,
  VersamentiApiFactory,
  StrumentiApiFactory,
  UtentiApiFactory,
  DashboardApiFactory,
  FidelityApiFactory,
  EndlessAisleApiFactory,
  DigitalLoyaltyCardApiFactory,
  StatesApiFactory,
} from '@/api'
import { GeneralApiFactory } from '@/api/om'
import { OM_BASE_PATH_PROD, OM_BASE_PATH_TEST } from '@/configs'
import store from '@/store'
import { AuthActions, AuthGetters } from '@/store/auth'
import { ConfigGetters } from '@/store/configs-store'
import { AppPreferences } from '@awesome-cordova-plugins/app-preferences'
import axios, { AxiosError, AxiosRequestConfig } from 'axios'
import { OMAuthenticationHelper } from './authentication'
import { MobileDeviceSettingKeys } from './enums'
import { getPoswebOrigin } from './functions'
import { logError } from './functions/logerrors'
import { isNativeMobileApp } from './mobile'
import { NotificationsActions } from '@/store/notifications-store'
import i18nInstance from './i18n'

const mockApiPort = import.meta.env.VITE_MOCK_API_PORT || 8000
const mockApiBasePath = `http://localhost:${mockApiPort}`

// PosWeb APIs
export let sessionApi: ReturnType<typeof SessionApiFactory>
export let storeDataApi: ReturnType<typeof StoreDataApiFactory>
export let consumerDataApi: ReturnType<typeof ConsumerApiFactory>
export let creditCardApi: ReturnType<typeof CreditCardApiFactory>
export let systemApi: ReturnType<typeof SystemApiFactory>
export let storeManagementApi: ReturnType<typeof StoreManagementApiFactory>
export let storeConfigs: ReturnType<typeof SystemApiFactory>
export let catalogApi: ReturnType<typeof CatalogApiFactory>
export let salesApi: ReturnType<typeof SalesApiFactory>
export let paymentApi: ReturnType<typeof PaymentsApiFactory>
export let itemsApi: ReturnType<typeof ItemsApiFactory>
export let couponsApi: ReturnType<typeof CouponsApiFactory>
export let omTokenApi: ReturnType<typeof OMTokenApiFactory>
export let depositsApi: ReturnType<typeof VersamentiApiFactory>
export let pettyCashApi: ReturnType<typeof PettyCashApiFactory>
export let reintegriApi: ReturnType<typeof ReintegriApiFactory>
export let priceUpdateApi: ReturnType<typeof AggiornamentoPrezziApiFactory>
export let cashClosingApi: ReturnType<typeof ChiusuraGiornalieraApiFactory>
export let salesListApi: ReturnType<typeof ListaVenditeApiFactory>
export let giftCardApi: ReturnType<typeof GiftCardsApiFactory>
export let b2eRefundsApi: ReturnType<typeof B2ERefundsApiFactory>
export let laybyApi: ReturnType<typeof AccontiApiFactory>
export let rfidApi: ReturnType<typeof RFIDApiFactory>
export let returnsApi: ReturnType<typeof ResiApiFactory>
export let sospesiApi: ReturnType<typeof SospesiApiFactory>
export let dashboardApi: ReturnType<typeof DashboardApiFactory>
export let usersApi: ReturnType<typeof UtentiApiFactory>
export let toolsApi: ReturnType<typeof StrumentiApiFactory>
export let fidelityApi: ReturnType<typeof FidelityApiFactory>
export let digitalLoyaltyCardApi: ReturnType<
  typeof DigitalLoyaltyCardApiFactory
>
export let endlessAisleApi: ReturnType<typeof EndlessAisleApiFactory>
export let stateApi: ReturnType<typeof StatesApiFactory>
// OM APIs
export let omApi: ReturnType<typeof GeneralApiFactory>

export abstract class AbortControllerHelper {
  static controller: AbortController

  static init() {
    this.controller = new AbortController()
  }

  static abortAndInit() {
    this.controller?.abort()
    this.init()
  }

  /**
   * Returns an instance of an AbortController that has been combined with other AbortController(s).
   * This allows us to have a centralized AbortController and multiple other instances of
   * AbortController(s) that can be triggered manually. The resulting AbortController, aborts HTTP
   * requested once **ANY** of the combined AbortController aborts.
   * @param controllers An optional list of AbortController(s) to combine togheter.
   */
  static getCombinedControllers(...controllers: AbortController[]) {
    const controller = new AbortController()

    if (!controllers.includes(this.controller)) {
      controllers.push(this.controller)
    }

    for (const c of controllers) {
      if (c.signal.aborted) {
        return c
      }

      c.signal.addEventListener(
        'abort',
        () => controller.abort(c.signal.reason),
        {
          signal: controller.signal,
        },
      )
    }

    return controller
  }
}

/**
 * If running in a mobile app (e.g. user is using this web app as an hybrid app running on an iPad),
 * this will fetch configs from the device settings, reading required URLs.
 * Then it instantiates each API helper we have.
 * If running in a browser, the first step will be skipped and resulting APIs calls will be sent to
 * the server currently hosting this web app.
 */
export async function prepareApiHelpers() {
  const basePath = parseInt(import.meta.env.VITE_ARE_MOCK_API_ENABLED, 10)
    ? mockApiBasePath
    : await getPoswebOrigin()
  let baseOMPath = parseInt(import.meta.env.VITE_ARE_MOCK_API_ENABLED, 10)
    ? mockApiBasePath
    : ''

  if (isNativeMobileApp()) {
    const omUrl: string = await AppPreferences.fetch(
      MobileDeviceSettingKeys.OM_URL,
    )
    baseOMPath = omUrl.replace(/\/$/, '')
  }

  AbortControllerHelper.abortAndInit()
  instantiateApiHelper(basePath)

  omApi = createOmProxyObject(baseOMPath)
}

/**
 * This function instantiates every API helper used by this application.
 * It's automatically called by the `prepareApiHelpers` function.
 * It can be called once again to reconfigure those helpers, so that they use
 * a different base url for subsequent requests.
 * For example this method is used to start communicating with a cash when this web
 * app is used as a native app running in iOS.
 * @param basePath The new base URL
 * @see prepareApiHelpers
 */
export function instantiateApiHelper(basePath: string) {
  const configuration = getAxiosConfigs()

  sessionApi = SessionApiFactory(configuration, basePath)
  storeDataApi = StoreDataApiFactory(configuration, basePath)
  consumerDataApi = ConsumerApiFactory(configuration, basePath)
  creditCardApi = CreditCardApiFactory(configuration, basePath)
  systemApi = SystemApiFactory(configuration, basePath)
  storeManagementApi = StoreManagementApiFactory(configuration, basePath)
  storeConfigs = SystemApiFactory(configuration, basePath)
  catalogApi = CatalogApiFactory(configuration, basePath)
  salesApi = SalesApiFactory(configuration, basePath)
  paymentApi = PaymentsApiFactory(configuration, basePath)
  itemsApi = ItemsApiFactory(configuration, basePath)
  couponsApi = CouponsApiFactory(configuration, basePath)
  omTokenApi = OMTokenApiFactory(configuration, basePath)
  depositsApi = VersamentiApiFactory(configuration, basePath)
  laybyApi = AccontiApiFactory(configuration, basePath)
  pettyCashApi = PettyCashApiFactory(configuration, basePath)
  reintegriApi = ReintegriApiFactory(configuration, basePath)
  priceUpdateApi = AggiornamentoPrezziApiFactory(configuration, basePath)
  salesListApi = ListaVenditeApiFactory(configuration, basePath)
  cashClosingApi = ChiusuraGiornalieraApiFactory(configuration, basePath)
  b2eRefundsApi = B2ERefundsApiFactory(configuration, basePath)
  rfidApi = RFIDApiFactory(configuration, basePath)
  giftCardApi = GiftCardsApiFactory(configuration, basePath)
  sospesiApi = SospesiApiFactory(configuration, basePath)
  usersApi = UtentiApiFactory(configuration, basePath)
  dashboardApi = DashboardApiFactory(configuration, basePath)
  toolsApi = StrumentiApiFactory(configuration, basePath)
  returnsApi = ResiApiFactory(configuration, basePath)
  fidelityApi = FidelityApiFactory(configuration, basePath)
  endlessAisleApi = EndlessAisleApiFactory(configuration, basePath)
  digitalLoyaltyCardApi = DigitalLoyaltyCardApiFactory(configuration, basePath)
  stateApi = StatesApiFactory(configuration, basePath)
}

axios.interceptors.request.use(
  async (config) => {
    if (
      config.url?.includes('sessions') &&
      !config.url?.includes('connection_status')
    ) {
      return config
    }

    // Get posweb intance url to check if axios is intercepting requests
    // to posweb or other services
    const poswebInstance =
      store.getters[ConfigGetters.GET_SELECTED_CASH_URL] ||
      (await getPoswebOrigin())

    const sid = store.getters[AuthGetters.GET_SID]

    if (poswebInstance && sid && config.headers) {
      if (config.url?.match(/([a-z.]*)om\.mmfg\.it/)) {
        // if thw request is issued to OM service, add JWT token
        config.headers.Authorization = `Bearer ${OMAuthenticationHelper.getToken()}`
      } else if (config.url?.includes(poswebInstance)) {
        // if the request is issued to Posweb server add SID in header for authentication
        config.headers.Authorization = `Bearer ${sid}`
      }
    }
    return config
  },
  (error) => {
    return Promise.reject(error)
  },
)

axios.interceptors.response.use(
  (response) => response,
  async (error) => {
    logError(error)
    // If we are logged in and we got a 401 or a 403, proceed with the logout process.
    if (
      store.getters[AuthGetters.GET_SID] &&
      (isHttpError(error, 401) || isHttpError(error, 403)) &&
      !error.config.url?.match(/([a-z.]*)om\.mmfg\.it/)
    ) {
      const $t = i18nInstance.global.t
      const message =
        $t('common.session_expired_long_version') +
        '\n' +
        $t('common.new_login')

      await store.dispatch(NotificationsActions.NOTIFY_ERROR, message, {
        root: true,
      })
      await store.dispatch(AuthActions.LOGOUT, null, { root: true })
    }
    // if the request has been stopped by AbortController we return the proper http status
    if (axios.isCancel(error)) {
      return Promise.resolve({ status: 499 })
    }
    return Promise.reject(error)
  },
)

/**
 * Checks whether the received ip address is valid.
 * Note: this function only supports IPv4 addresses.
 *
 * Validation follows these rules:
 *  - An empty string is not a valid IP
 *  - Both `0.0.0.0` and `255.255.255.0` are not valid IPs
 *  - If the received string looks like an IP address, that string will be considered
 *    as a valid IP (independently of subnetting rules and similar things).
 * @param ipAddress The IP address to validate
 * @returns `true` if valid. `false` otherwise.
 */
export function isValidCashIp(ipAddress: string) {
  if (!ipAddress) {
    return false
  }

  if (ipAddress === '0.0.0.0' || ipAddress === '255.255.255.0') {
    return false
  }

  const ipv4Regex =
    /^((25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/
  return ipv4Regex.test(ipAddress)
}

/**
 * Returns true if the specified exception is an HTTP error.
 * Additionally, you can specify an HTTP status code to test for equality.
 * Doing so, we do not expose underlying implementation details.
 * @param exception The caught exception to test
 * @param status Check whether the exception is an HTTP error with a specific status code
 */
export function isHttpError<T>(
  exception: unknown,
  status?: number,
): exception is AxiosError<T, unknown> {
  const isError = axios.isAxiosError(exception)

  if (typeof status !== 'undefined') {
    return isError && exception.response?.status === status
  }

  return isError
}

/**
 * Returns true if the received error message is the result of a cancelled/aborted HTTP request.
 * @param error The caught exception to test
 */
export function isCancel(error: unknown) {
  return axios.isCancel(error)
}

/**
 * An utility function to delay instantiation of the `omApi` helper.
 * Doing so, we can switch between production and testing OM, based on the deployment
 * enviroment of the current Posweb instance.
 * This has no effect if running in a native mobile app.
 * @param baseOMPath Current OM base path
 */
function createOmProxyObject(baseOMPath: string) {
  const target = {} as unknown as ReturnType<typeof GeneralApiFactory>
  const configuration = getAxiosConfigs()

  return new Proxy(target, {
    get: function (
      target: ReturnType<typeof GeneralApiFactory>,
      prop: keyof typeof GeneralApiFactory,
    ) {
      if (Object.keys(target).length === 0) {
        if (!isNativeMobileApp()) {
          const isProd = store.getters[ConfigGetters.IS_LIVE_ENVIRONMENT]
          baseOMPath = isProd ? OM_BASE_PATH_PROD : OM_BASE_PATH_TEST
        }

        const result = GeneralApiFactory(configuration, baseOMPath)

        Object.assign(target, result)
      }

      return target[prop]
    },
  })
}

export function getAxiosConfigs() {
  const baseOptions: AxiosRequestConfig = {
    signal: AbortControllerHelper.controller?.signal,
  }

  return new Configuration({ baseOptions: baseOptions })
}
